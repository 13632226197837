import { Paper, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import { voucherAtom, setAppliedPromoCode } from 'src/state/voucher/voucherAtom';

import { GiftTypeEnum } from 'src/components/domain/gift-type-selection/GiftTypeSelections';
import { useLocale } from '@repo/i18n';
import SummaryGiftcardBooking from '../SummaryGiftcardBooking/SummaryGiftcardbooking';
import SummaryGiftcardPayment from '../SummaryGiftcardPayment/SummaryGiftcardPayment';
import SummaryGiftcardVat from '../SummaryGiftcardVat/SummaryGiftcardVat';
import { TZDate } from '@repo/tzdate';
import { GiftCardPromoCode } from 'src/components/domain/create-giftcard/GiftCardPromoCode';

interface IProps {
    giftcardTotal: number;
    giftcardValue: number;
    selectedGiftType: GiftTypeEnum | null;
    showPrintButton?: boolean;
    showNextButton?: boolean;
    goPayClicked?: () => void;
    isCreatingGiftcard?: boolean;
    expiryDate?: TZDate;
    promoCodeWritable?: boolean;
}

export default function SummaryGiftcardReceipt(props: IProps): JSX.Element {
    const {
        giftcardTotal,
        giftcardValue,
        showPrintButton = false,
        showNextButton = false,
        goPayClicked,
        isCreatingGiftcard = false,
        promoCodeWritable = false,
    } = props;
    const { t } = useLocale();
    const theme = useTheme();
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [{ appliedPromoCode }, voucherDispatch] = useAtomReducer(voucherAtom);

    return (
        <Paper
            sx={{
                marginTop: theme.spacing(2),
                padding: theme.spacing(3, 3, 2, 3),
                [theme.breakpoints.down('md')]: {
                    maxWidth: '440px',
                },
                width: '100%',
                backgroundColor: theme.palette.grey[50],
                color: theme.palette.primary.main,
                '@media print': {
                    '& .no-print': {
                        display: 'none',
                    },
                },
            }}
            variant="outlined"
        >
            <Typography
                color="textSecondary"
                variant="h4"
                pb={theme.spacing(2)}
                fontWeight={500}
                fontSize={28}
            >
                {t.summary}
            </Typography>
            <SummaryGiftcardBooking
                giftcardTotal={giftcardValue}
                selectedGiftType={props.selectedGiftType}
                largeScreen={largeScreen}
                expiryDate={props.expiryDate}
            />
            <SummaryGiftcardVat giftcardPrice={giftcardValue} />
            <GiftCardPromoCode
                onPromoCodeApplied={(promoCode) => {
                    voucherDispatch(setAppliedPromoCode(promoCode));
                }}
                appliedPromoCode={appliedPromoCode}
                currentPriceReduction={
                    appliedPromoCode?.coupon_type === 'percent'
                        ? giftcardValue * (appliedPromoCode?.coupon_value / 100)
                        : (appliedPromoCode?.coupon_value ?? 0)
                }
                writable={promoCodeWritable}
            />
            <SummaryGiftcardPayment
                showPrintButton={showPrintButton}
                showNextButton={showNextButton}
                totalPrice={giftcardTotal}
                goPayClicked={goPayClicked}
                isCreatingGiftcard={isCreatingGiftcard}
            />
        </Paper>
    );
}
