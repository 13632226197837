/* eslint-disable max-len */

import { RawTranslations } from '@repo/types';

const translations: RawTranslations = {
    show_cancellation_policy: "Afficher la politique d'annulation",
    hide_cancellation_policy: "Masquer la politique d'annulation",
    departure: 'Départ',
    price: 'Prix',
    difficulty: 'Difficulté',
    duration: 'Durée',
    starting_time: 'Heure de départ',
    about: 'À propos de',
    book_now: 'Réserver maintenant',
    book: 'Réserver',
    buy_as_gift: 'Acheter comme cadeau',
    from: 'De',
    your_booking_reference_is: 'Votre numéro de réservation est',
    booking_details_in_email: "Vous recevrez bientôt un e-mail avec plus d'informations",
    see_more_activities: "Voir plus d'activités",
    your_cart: 'Votre panier',
    in_your_cart: 'Dans votre panier',
    when_are_you_going: 'Choisissez une date et une heure',
    how_many_are_going: 'Combien de personnes vont participer ?',
    contact_details: 'Coordonnées',
    traveler: 'Voyageur',
    extras: 'Extras',
    please_select: 'Veuillez sélectionner',
    no_items_in_cart: "Il n'y a aucun article dans votre panier",
    payment: 'Paiement',
    receipt: 'Reçu',
    do_you_have_any_comments_or_special_needs:
        'Avez-vous des commentaires ou des besoins spéciaux ?',
    i_have_read_and_accepted_the: "J'ai lu et accepté la",
    privacy_policy: 'Politique de confidentialité',
    terms_and_conditions: 'Conditions générales',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    phone_number: 'Numéro de téléphone',
    dial_code: 'Indicatif téléphonique',
    email: 'E-mail',
    please_register: 'Veuillez vous inscrire',
    main_contact: 'Contact principal',
    additional_information: 'Informations supplémentaires',
    remove_cart_item: "Supprimer l'article du panier",
    back_to_webshop: 'Continuer les achats',
    excl: 'hors',
    vat: 'TVA',
    total: 'Total',
    payNow: 'Paye maintenant',
    payLater: 'Paye plus tard',
    only_deposit_amount_to_pay_now: {
        template: 'Seulement <NAME> à payer en dépôt maintenant.',
    } as any,
    next: 'Suivant',
    go_and_pay: 'Payer',
    print: 'Imprimer',
    close: 'Fermer',
    checkout: 'Passer à la caisse',
    summary: 'Résumé',
    cancel: 'Annuler',
    save: 'Enregistrer',
    select_time: "Sélectionner l'heure",
    selected_time: 'Heure sélectionnée',
    time: 'Heure',
    go_back: 'Retour',
    thank_you_for_booking_with_us: "Merci d'avoir réservé avec nous !",
    please_fill_in_all_required_information: 'Veuillez remplir toutes les informations requises',
    error_occurred_when_creating_reservation:
        "Une erreur s'est produite lors de la création de la réservation",
    error_occurred_when_creating_giftcard:
        "Une erreur s'est produite lors de la création de la carte-cadeau",
    couldnt_apply_promocode_is_the_code_correct:
        "Impossible d'appliquer le code promo. Le code est-il correct ?",
    choose_a_country: 'Choisissez un pays',
    select_date: 'Sélectionner une date',
    select_travelers: 'Sélectionner les voyageurs',
    hourShort: 'h',
    dayShort: 'j',
    minuteShort: 'min',
    years: 'ans',
    age: 'Âge',
    more_information: "Plus d'informations",
    same_as_main_contact: 'Identique au contact principal',
    easy: 'Facile',
    medium: 'Moyen',
    hard: 'Difficile',
    please_select_date: 'Veuillez sélectionner une date',
    please_select_date_and_time: 'Veuillez sélectionner une date et une heure',
    name: 'Nom',
    comment: 'Commentaire',
    submit_request: 'Envoyer la demande',
    any_special_requests_etc: 'Des demandes spéciales, des allergies alimentaires... ?',
    request_tour_comment: 'Décrivez votre demande',
    thank_you_for_your_request: 'Merci pour votre demande, nous vous répondrons rapidement.',
    got_it: "C'est compris !",
    continue_shopping: 'Continuer les achats',
    continue_travelers: 'Voyageurs',
    please_provide_your_name: 'Veuillez fournir un nom',
    please_provide_your_email: 'Veuillez fournir une adresse e-mail valide',
    please_provide_a_description: 'Veuillez fournir une description',
    an_error_occurred_when_submitting_your_request:
        "Une erreur s'est produite lors de l'envoi de votre demande. Veuillez réessayer plus tard.",
    private_tour: 'Visite privée',
    read_more_and_book: 'En savoir plus et réserver',
    read_more: 'En savoir plus',
    prices_from: 'Prix à partir de',
    about_the_trip: "À propos de l'excursion",
    prices: 'Prix',
    whats_included: 'Ce qui est inclus',
    any: 'Tout',
    duration_up_to_1_hour: "Jusqu'à 1 heure",
    duration_1_2_hours: '1 - 2 heures',
    duration_2_6_hours: '2 - 6 heures',
    duration_6_12_hours: '6 - 12 heures',
    duration_12_24_hours: '12 - 24 heures',
    duration_1_2_days: '1 - 2 jours',
    duration_more_than_2_days: 'Plus de 2 jours',
    find_activities: 'Trouver des activités',
    ok: 'OK',
    clear: 'Effacer',
    filterActivities: 'Filtrer les activités',
    hideFilters: 'Masquer les filtres',
    shopMore: 'Acheter plus',
    noSearchResults:
        "Désolé, nous n'avons trouvé aucune activité disponible correspondant à votre recherche.",
    update: 'Mettre à jour',
    select_date_to_view_timeslots:
        'Choisissez une date pour voir les créneaux horaires disponibles',
    your_reservation_timed_out: 'Votre réservation a expiré. Veuillez réessayer.',
    aria_calendar_input_field_label:
        'Champ de saisie du calendrier. Appuyez sur Entrée pour ouvrir.',
    aria_book_now_button: 'Ouvrir une fenêtre pour réserver cette activité',
    aria_next_image: 'Image suivante',
    aria_previous_image: 'Image précédente',
    aria_open_booking_widget: 'Ouvrir la fenêtre de réservation',
    aria_close_booking_widget: 'Fermer la fenêtre de réservation',
    aria_open_request_tour_widget: 'Ouvrir la fenêtre de demande de visite',
    aria_close_request_tour_widget: 'Fermer la fenêtre de demande de visite',
    aria_open_find_activity: "Ouvrir la recherche d'activité",
    aria_close_find_activity: "Fermer la recherche d'activité",
    aria_show_giftcard_input: 'Afficher le champ de la carte cadeau',
    aria_hide_giftcard_input: 'Masquer le champ de la carte cadeau',
    fill_giftcard_code: 'Entrer le code de la carte cadeau',
    aria_show_promocode_input: 'Afficher le champ du code promotionnel',
    aria_hide_promocode_input: 'Masquer le champ du code promotionnel',
    aria_price_to_pay_summary: 'Résumé du prix à payer',
    fill_promocode: 'Entrer le code promotionnel',
    request_tour: 'Demande de visite',
    giftcard_message: 'message',
    giftcard_message_label: 'message personnel au destinataire (facultatif)',
    recipient_details: 'détails du destinataire',
    gift_card: 'carte-cadeau',
    value: 'valeur',
    create: 'créer',
    create_your_gift: 'créez votre carte-cadeau',
    your_giftcard_reference_is: 'votre référence est',
    giftcard_voucher_and_receipt:
        "nous vous avons envoyé un bon cadeau prêt à être imprimé ainsi qu'un reçu à :",
    giftcard_voucher: 'nous avons envoyé le bon cadeau à :',
    giftcard_and_receipt: 'et un reçu à vous à :',
    thank_you_for_your_purchase: 'merci pour votre achat !',
    valid_until: "valide jusqu'au",
    print_receipt: 'imprimer le reçu',
    select_gift_type: 'sélectionnez le type de cadeau',
    please_select_gift_type: 'veuillez sélectionner le type de cadeau',
    select_gift_print_description:
        'recevez une carte-cadeau PDF imprimable dans votre boîte de réception',
    select_gift_email_description:
        'envoyez une carte-cadeau par e-mail directement au destinataire',
    enter_giftcard_code: 'Entrez un code de carte-cadeau',
    enter_promo_code: 'Entrez un code promo',
    apply: 'appliquer',
    giftcard_code_error: 'code de carte-cadeau non valide',
    remaining_value: 'valeur restante',
    giftcard_code_not_found: 'impossible de trouver la carte-cadeau avec le code :',
    giftcard_zero_balance: 'la carte-cadeau est déjà utilisée',
    giftcard_is_expired: 'la carte-cadeau est expirée',
    purchased_giftcard: {
        self: 'carte-cadeau imprimable envoyée à vous',
        recipient: 'carte-cadeau imprimable envoyée au destinataire',
    },
    contact_information: {
        short: 'informations de contact',
        long: 'informations de contact',
    },
    minutes: {
        singular: 'min',
        plural: 'min',
        long: 'minute',
    },
    hours: {
        singular: 'heure',
        plural: 'heures',
    },
    days: {
        singular: 'jour',
        plural: 'jours',
    },
    children: {
        singular: 'enfant',
        plural: 'enfants',
    },
    adult: {
        singular: 'adulte',
        plural: 'adultes',
    },
    card: {
        singular: 'carte',
        plural: 'cartes',
    },
    number_of_travelers_exceeded: {
        template: 'Le nombre maximum de personnes ce jour-là est de <COUNT:person:personnes>',
    } as any,
    booking_is_closed: {
        template: 'La réservation pour <NAME> est désormais fermée',
    } as any,
    rooms: {
        singular: 'chambre',
        plural: 'chambres',
    },
    person: {
        singular: 'personne',
        plural: 'personnes',
    },
    select_room: 'sélectionner une chambre',
    change_room: 'changer de chambre',
    less_than: 'moins de',
    year: {
        singular: 'an',
        plural: 'ans',
    },
    select_age: "sélectionner l'âge",
    done: 'terminé',
    number_of_rooms: 'nombre de chambres',
    select_bed: 'sélectionner un lit',
    room_info: 'informations sur la chambre',
    price_one_night_one_guest: {
        template: 'prix pour <COUNT:night:nuit>, <COUNT:guest:invités>',
    } as any,
    one_night_one_guest: {
        template: '<COUNT:night:nuit>, <COUNT:guest:invités>',
    } as any,
    price_nights: {
        template: 'prix pour <COUNT:night:nuits>',
    } as any,
    night: {
        singular: 'nuit',
        plural: 'nuits',
    },
    select_room_for_total_price: 'sélectionnez des chambres pour obtenir le prix total',
    find_rooms: 'trouver des chambres',
    check_in_check_out: 'Arrivée et départ',
    guest_and_rooms: 'Invités et chambres',
    no_accommodations_found:
        "Nous sommes désolés, mais nous n'avons trouvé aucune chambre correspondant à vos critères de recherche. Avez-vous envisagé d'ajuster vos paramètres de recherche pour trouver des chambres disponibles ?",
    facilities_included: 'Équipements inclus',
    price_per_night: 'Prix par nuit',
    season: 'Saison',
    has_airconditioning: 'Climatisation',
    has_balcony: 'Balcon',
    has_bicycles: 'Vélos',
    has_boat: 'Bateau',
    has_coffee_maker: 'Machine à café',
    has_dishwasher: 'Lave-vaisselle',
    has_dryer: 'Sèche-linge',
    has_fireplace: 'Cheminée',
    has_garage: 'Garage',
    has_garden_furniture: 'Mobilier de jardin',
    has_grill: 'Grill',
    has_kitchen: 'Cuisine',
    has_minibar: 'Minibar',
    has_mooring_space: "Espace d'amarrage",
    has_pool: 'Piscine',
    has_roomservice: 'Service en chambre',
    has_sauna: 'Sauna',
    has_tv: 'Télévision',
    has_washing_machine: 'Machine à laver',
    has_wifi: 'WiFi',
    is_breakfast_included: 'Petit-déjeuner inclus',
    is_cancellation_included: "Possibilité d'annulation",
    is_pets_allowed: 'Animaux autorisés',
    is_smoking_allowed: 'Fumeurs autorisés',
    is_towels_and_bedlines_included: 'Serviettes et draps de lit inclus',
    is_wheelchair_accessible: 'Accessible en fauteuil roulant',
    wifi: 'WiFi',
    breakfast: 'Petit-déjeuner',
    accessible: 'Accessible',
    pets: 'Animaux',
    kitchen: 'Cuisine',
    smoking: 'Fumeurs',
    unknown: 'Inconnu',
    accommodation_cancellation_policy: "Politique d'annulation de l'hébergement",
    accommodations: 'Hébergements',
    activities: 'Activités',
    address: 'adresse',
    city: 'ville',
    post_code: 'code postal',
    to: 'à',
    date_range_start: 'début',
    date_range_end: 'fin',
    flag_of: 'drapeau de',
    monday: 'lundi',
    tuesday: 'mardi',
    wednesday: 'mercredi',
    thursday: 'jeudi',
    friday: 'vendredi',
    saturday: 'samedi',
    sunday: 'dimanche',
    today: "aujourd'hui",
    tomorrow: 'demain',
    january: 'janvier',
    february: 'février',
    march: 'mars',
    april: 'avril',
    may: 'mai',
    june: 'juin',
    july: 'juillet',
    august: 'août',
    september: 'septembre',
    october: 'octobre',
    november: 'novembre',
    december: 'décembre',
    few_left: 'quelques-uns restants',
    tickets_left: {
        template: '<COUNT:billet restant:billets restants>',
    } as any,
    available: 'disponible',
    sold_out: 'épuisé',
    upcoming_tours_title: 'Visites à venir',
    unavailable: 'indisponible',
    notes_regarding_your_stay: 'Notes concernant votre séjour',
    notes_for_accommodation_name_with_guests: {
        template: 'Notes pour <NAME> avec <COUNT:invité:invités>',
    } as any,
    select_price_type: 'Sélectionnez le type de prix',
    finding_available_rooms: 'Recherche de chambres disponibles',
    total_price: 'Prix total',
    of: 'de',
    select_dates: 'Sélectionnez des dates',
    please_select_price_type: 'Veuillez sélectionner le type de prix',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: `Un minimum de <COUNT> participants est requis pour réserver ce produit`,
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Une confirmation de réservation sera envoyée à votre adresse e-mail dès que nous aurons traité votre commande.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Une confirmation sera envoyée à votre adresse e-mail dès que nous aurons traité votre commande.',
    add: 'Ajouter',
    amount: 'Montant',
    areYouAMemberOrHaveValueCard: 'Êtes-vous membre ou avez-vous une carte de valeur ?',
    areYouSureYouWantToCancelYourBooking: 'Êtes-vous sûr de vouloir annuler cette réservation ?',
    areYouSureYouWantToCancelYourMembership: 'Êtes-vous sûr de vouloir annuler votre adhésion ?',
    automaticallyRenewed: 'Renouvellement automatique',
    at: 'À',
    backToWebsite: 'Retour au site Web',
    belayCertification: 'Certification de sécurité',
    belayCertificationConsent: "J'ai une certification de sécurité",
    buyMembership: 'Acheter une adhésion',
    buyNow: 'Acheter maintenant',
    cancelled: 'Annulé',
    canBeCancelledFrom: 'Peut être annulé à partir de',
    choose_payment_method: 'Choisissez le mode de paiement',
    bookNow: 'Acheter maintenant',
    bookTimeslot: 'Acheter des billets',
    cancellation: 'Annulation',
    choose: 'Choisir',
    chooseACountry: 'Choisir un pays',
    chooseCreditsType: 'Choisissez le type de crédits',
    chooseDateAndTime: "Choisissez la date et l'heure",
    chooseNumberOfCredits: 'Choisissez le nombre de crédits',
    chooseStartDate: 'Choisissez la date de début',
    chooseType: 'Choisissez le type',
    ticketCode: 'Code de commande',
    completed: 'Terminé',
    consents: 'Consentements',
    contact: 'Contact',
    contactByEmail: 'Contacter par e-mail',
    contactBySms: 'Contacter par SMS',
    couldntApplyGiftcardIsTheCodeCorrect:
        "Impossible d'appliquer la carte-cadeau. Le code est-il correct ?",
    couldntApplyPromocodeIsTheCodeCorrect:
        "Impossible d'appliquer le code promo. Le code est-il correct ?",
    couldntGetCompanyInformation: "Impossible d'obtenir les informations de l'entreprise",
    couldntGetPaymentPlan:
        "Impossible d'obtenir le plan de paiement. Veuillez essayer de rafraîchir la page Web.",
    couldntGetPaymentPlanUsingPreviousPlan:
        "Impossible d'obtenir le plan de paiement en utilisant le plan précédent",
    couldntGetValueCards: "Impossible d'obtenir les cartes de valeur",
    couldntGetProductPleaseTryAgain: "Impossible d'obtenir le produit, veuillez réessayer",
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        "Impossible d'ouvrir la caisse de réservation, toutes les informations de réservation requises n'ont pas été spécifiées.",
    couldntOpenProfilePleaseSignInFirst:
        "Impossible d'ouvrir le profil, veuillez vous connecter d'abord.",
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        "Impossible d'ouvrir la caisse de la carte de valeur, toutes les informations requises de la carte de valeur n'ont pas été spécifiées.",
    country: 'Pays',
    createAccount: 'Créer un compte',
    credit: {
        singular: 'Crédit',
        plural: 'Crédits',
    },
    credits: 'Crédits',
    creditsLeft: 'Crédits restants',
    creditDiscount: 'Réduction de crédit',
    discount: 'Réduction',
    free: 'Gratuit',
    date: 'Date',
    delete: 'Supprimer',
    doYouHaveAnAccount: 'Avez-vous un compte ?',
    doYouHaveValueCards: 'Avez-vous un compte ou des cartes de valeur ?',
    doYouHaveBrattkort: 'Avez-vous un Brattkort ou une certification de sécurité ?',
    doYouHaveClimbingCertification: "Avez-vous une certification d'escalade ?",
    edit: 'Modifier',
    editTimeslot: "Modifier l'horaire",
    failed: 'Échoué',
    firstName: 'Prénom',
    giftcard: 'Carte cadeau',
    goToMyPage: 'Aller sur ma page',
    ifYouWantToDeleteYourProfile: 'Si vous souhaitez supprimer votre profil',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'Vous pouvez acheter des billets depuis la boutique sur ce site Web.',
    yourOrderReferenceIs: 'Votre numéro de commande est',
    iHaveReadAndAccepted: "J'ai lu et accepté les",
    lastName: 'Nom de famille',
    postalLocation: 'Emplacement',
    location: 'Emplacement',
    loggedInAs: 'Connecté en tant que',
    login: 'Se connecter',
    logout: 'Se déconnecter',
    membershipCard: 'Carte de membre',
    membershipNumber: 'Numéro de membre',
    membershipSlashCredits: 'Adhésion / Crédits',
    monthlyCharge: 'Frais mensuels',
    myAddress: 'Mon adresse',
    myPage: 'Ma page',
    myProfile: 'Mon profil',
    nextCreateAccount: 'Suivant : Créer un compte',
    no: 'Non',
    noneSelected: 'Aucune sélection',
    noSelectedDate: 'Aucune date sélectionnée',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        "Tous les champs n'ont pas été remplis, veuillez remplir tous les champs.",
    noUserInfoFound: 'Aucune information utilisateur trouvée.',
    numberOfPersons: {
        singular: 'personne',
        plural: 'personnes',
        template: '<COUNT:person:personnes>',
    } as any,
    pay: 'Payer',
    payWithCreditCard: 'Payer avec une carte de crédit',
    phoneNumber: 'Numéro de téléphone',
    pleaseChoose: 'Veuillez choisir',
    pleaseContact: 'Veuillez contacter',
    pleaseFillOutUserInfoInProfile:
        'Veuillez remplir les informations utilisateur dans votre profil.',
    pleaseLogInToBook: 'Veuillez vous connecter pour acheter avec ces',
    pleaseLogInToPurchase:
        'Veuillez vous connecter et nous remplirons vos informations de contact pour vous',
    pleaseProvideAllValueCardInformation:
        'Veuillez fournir toutes les informations de la carte de valeur',
    pleaseProvideProperBookingInformation: 'Veuillez fournir des informations valides',
    pleaseRegister: 'Veuillez vous inscrire',
    postalCodeAndLocation: 'Code postal / emplacement',
    prefix: 'Préfixe',
    privacyPolicy: 'Politique de confidentialité',
    problemLoadingData: 'Erreur lors du chargement des données',
    processing: 'Traitement',
    purchaseValueCard: 'Acheter une carte de valeur',
    readMoreAbout: 'En savoir plus sur notre',
    readMoreAndBook: 'En savoir plus et acheter',
    readMoreAndBuy: 'En savoir plus et acheter',
    refillCredits: 'Recharger les crédits',
    returnToWebsite: 'Revenir au site Web',
    scanTicket: 'Scanner le billet',
    signUp: "S'inscrire",
    subtract: 'Soustraire',
    shoesAndHarnessAlwaysIncluded: 'Chaussures et harnais toujours inclus.',
    shoesAndHarnessNotIncluded: 'Chaussures et harnais non inclus.',
    somethingWentWrongUpdatingUserInfo:
        "Une erreur s'est produite lors de la mise à jour des informations utilisateur.",
    termsOfUse: {
        template: "Conditions d'utilisation de <NAME>",
    } as any,
    upcomingBookings: 'Réservations à venir',
    updateAddress: "Mettre à jour l'adresse",
    terminate: 'Résilier',
    thankYouForBookingWithUs: "Merci d'avoir réservé avec nous.",
    thePrivacyPolicy: 'Politique de confidentialité',
    thankYouForShoppingWithUs: 'Merci de faire vos achats avec nous.',
    thisGiftcardIsEmpty: 'Cette carte cadeau est vide.',
    tHours: 'h',
    totalCredits: 'Crédits totaux',
    totalNOK: 'Total NOK',
    updateBooking: 'Mettre à jour la réservation',
    updateProfile: 'Mettre à jour le profil',
    useGiftcard: 'Utiliser la carte cadeau',
    useGiftcardOrPromocode: 'Utiliser la carte cadeau / code promotionnel',
    validationNotANumber: 'Doit être un nombre',
    validationMaxNumber: {
        template: 'Pas de chiffres au-dessus de <NUM>',
    } as any,
    validationMinNumber: {
        template: 'Pas de chiffres en dessous de <NUM>',
    } as any,
    validForThePeriod: 'Valable pour la période',
    validUntil: "Valable jusqu'au",
    validFrom: 'Valable à partir de',
    valueCard: 'Carte de valeur',
    visitors: 'Visiteurs',
    withoutVat: 'Sans TVA',
    yes: 'Oui',
    youDontHaveAnyUpcomingBookings: "Vous n'avez aucune réservation à venir.",
    youDontHaveAnyValueCards: "Vous n'avez aucune carte de valeur",
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        "Vous n'avez pas d'adhésion valide, souhaitez-vous en acheter une ?",
    youMustSpecifyAValueCard: 'Vous devez spécifier une carte de valeur valide.',
    yourReservationTimedOut: 'Votre réservation a expiré.',
    zipcode: 'Code postal',
    payByMembershipCard: 'Payer par carte de membre',
    payWith: 'Payer avec',
    deleteMyProfile: 'Supprimer mon profil',
    areYouSureYouWantToDeleteYourProfile: 'Êtes-vous sûr de vouloir supprimer votre profil ?',
    deleteYourProfileInformation:
        'Vous êtes sur le point de supprimer et de déconnecter les informations de votre profil de toutes les réservations et produits achetés. Cette action est irréversible. Certaines informations peuvent être conservées pour des raisons légales.',
    choosePaymentOtherMethods: "Choisir d'autres méthodes de paiement",
    dontUseMembershipCardForThisBooking:
        'Ne pas utiliser la carte de membre pour cette réservation',
    nameNotProvided: "Le nom n'est pas fourni",
    phoneNumberNotProvided: "Le numéro de téléphone n'est pas fourni",
    emailNotProvided: "L'adresse e-mail n'est pas fournie",
    addressNotProvided: "L'adresse n'est pas fournie",
    promocode: 'Code promo',
    show_usage_terms: "Afficher les conditions d'utilisation",
    hide_usage_terms: "Masquer les conditions d'utilisation",
    about_the_product: 'à propos du produit',
    successful: 'Succès',
    selectProduct: 'Sélectionner un produit',
    withCreditCard: 'Avec une carte de crédit',
    paymentMethod: 'Méthode de paiement',
    noAvailableTimeslots: 'Aucun créneau horaire disponible',
    orderReference: 'Référence de commande',
    iWishToRecieveNewsletters: 'Je souhaite recevoir des newsletters',
    mySite: 'Mon site',
    pleaseConfirm: {
        template: 'Veuillez confirmer "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Veuillez indiquer le nombre de participants',
    pleaseSelectTheDateAndTimeOfYourBooking:
        "Veuillez sélectionner la date et l'heure de votre réservation",
    pleaseSelectNumberOfCredits: 'Veuillez sélectionner le nombre de crédits',
    pleaseSelectTypeOfCreditcard: 'Veuillez sélectionner le type de carte de crédit',
    pleaseSelectAStartDate: 'Veuillez sélectionner une date de début',
    pleaseSelectAMembershipType: "Veuillez sélectionner un type d'adhésion",
    contactCustomerServiceToDeleteYourProfile:
        'Contactez le service client pour supprimer votre profil',
    inclInMembership: "Inclus dans l'adhésion",
    maxAmount: 'Montant maximum',
    checkIn: 'Arrivée',
    checkOut: 'Départ',

    anErrorOccurredDuringPayment: 'Une erreur est survenue lors du paiement',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        "Une erreur est survenue, veuillez réessayer en rechargeant la page. Message d'erreur",
    share: 'Partager',
    copyLink: 'Copier le lien',
    howDoYouWantToShare: 'Comment souhaitez-vous partager ?',

    shoppingCart: "Panier d'achat",
    heresMyShoppingCart: "Voici mon panier d'achat",
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        "Il n'y a pas assez de capacité pour les articles dans ce panier",

    full: 'Complet',
    no_available_capacity_for_this_tour: 'Pas de capacité disponible pour cette visite',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        "Une ou plusieurs activités n'ont pas suffisamment de capacité",

    pick_a_date: 'Choisissez une date',
    package: 'Forfait',
    packages: 'Forfaits',
    customize_activities: 'Personnaliser les activités',
    check_in_at: 'Arrivée à',
    activity_starts_at: "L'activité commence à",
    other_times_are_available: "D'autres horaires sont disponibles",
    press_to_select_other_options: "Appuyez pour sélectionner d'autres options",
    unavailableForSelectedTime: "Indisponible à l'heure sélectionnée",
    order_status_awaiting_payment: 'En attente de paiement',
    order_status_payment_failed: 'Paiement échoué',
    awaiting_payment_info:
        'Traitement du paiement... cela ne prend généralement que quelques minutes.',
    cancel_membership: "Annuler l'adhésion",
    update_subscription_payment_details: "Mettre à jour les détails de paiement de l'abonnement",
    payment_failed_info:
        'Il semble y avoir un problème avec votre paiement. Vous pouvez essayer de mettre à jour les détails de paiement.',
    retry_subscription_payment: 'Réessayer le paiement maintenant',
    company: 'Entreprise',
    company_name: "Nom de l'entreprise",
    org_number: "Numéro d'entreprise",
    pay_with_invoice: 'Payer avec une facture',
    continueWithRebooking: 'Continuer avec la nouvelle réservation',
    searchAndBookAccommodations: 'Rechercher et réserver des hébergements',
    selectNumberOfGuests: "Sélectionnez le nombre d'invités",
    checkAvailability: 'Vérifier la disponibilité',
    updateAvailability: 'Mettre à jour la disponibilité',
    you_will_have_to_switch_rooms_during_your_stay:
        'Vous devrez changer de chambre pendant votre séjour. Contactez-nous pour procéder à la réservation.',
    selected: 'Sélectionné',
    remove: 'Retirer',
    aria_increase: 'Augmentation',
    aria_decrease: 'Diminution',
    promo_code_not_supported_for_gift_cards:
        "Le code promo n'est pas pris en charge pour les cartes cadeau",
    select_products: 'Sélectionner des produits',
    change_selected_products: 'Changer les produits sélectionnés',
    cartCompatibilityWarning:
        'Il y a des produits dans votre panier qui ne sont pas compatibles avec ce produit. Veuillez les acheter séparément ou vider votre panier pour continuer.',
    membershipBookingDisabledWarning:
        "La réservation pour les membres est désactivée sur ce site, mais ce produit en nécessite une. Il s'agit très probablement d'une mauvaise configuration. Veuillez contacter le service client.",
    see_available_tickets: 'Voir les billets disponibles',
} as any;

export default translations;
