import { useCallback } from 'react';
import { Atom, useAtom } from 'ximple';

type Action = { value?: unknown; type: string };

export type ThunkAction<T, A extends Action> = (dispatch: (action: A) => void, state: T) => void;

export const useAtomReducer = <T, A extends Action>(
    atom: Atom<T, A>,
): [T, (action: A | ThunkAction<T, A>) => void] => {
    const [value, dispatch] = useAtom(atom);
    const dispatcher = useCallback(
        (action: A | ThunkAction<T, A>) =>
            typeof action === 'object' ? dispatch(action) : action(dispatch, value),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch],
    );

    return [value, dispatcher];
};
