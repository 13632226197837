import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useAtom } from 'ximple';
import { usePromocodeStatus } from '@repo/widget-utils/services/hooks/product';
import { isGiftcardStatusValid } from '@repo/widget-utils/voucherUtils';
import { getGiftcardStatus } from '@repo/widget-utils/services/fetchers/product';
import { BilberryApiError } from '@repo/widget-utils/services/utils/BilberryApiError';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import {
    voucherAtom,
    resetAppliedPromoCode,
    resetGiftcards,
    resetIgnoredValueCards,
    resetPromoCode,
    selectHasIgnoredValueCards,
    setAppliedPromoCode,
    setGiftcards,
} from '../voucher/voucherAtom';
import { cartAtom } from '../cart/cartAtom';

// On pageload, and if cart is empty, reset all voucher values except
// promocode.
// We leave promocode value alone since it is polled in a separate hook
// below (usePromoCodeEffects).
export function useResetVoucherEffects() {
    const [cart] = useAtom(cartAtom);
    const [voucher, voucherDispatch] = useAtomReducer(voucherAtom);
    const { giftcards, appliedPromoCode } = voucher;
    const [pageloadReset, setPageloadReset] = useState(false);

    // On initial pageload we reset giftcards, valuecards and appliedPromoCode, once.
    useEffect(() => {
        if (pageloadReset) return;

        if (!pageloadReset) setPageloadReset(true);

        if (selectHasIgnoredValueCards(voucher)) voucherDispatch(resetIgnoredValueCards());

        // If cart is not empty, return.
        // We only clear the values below if cart is empty.
        if (cart.length) return;

        if (giftcards.length) voucherDispatch(resetGiftcards());

        if (appliedPromoCode) voucherDispatch(resetAppliedPromoCode());
    }, [
        pageloadReset,
        setPageloadReset,
        appliedPromoCode,
        cart.length,
        giftcards.length,
        voucher,
        voucherDispatch,
    ]);
}

// Check if promocode is valid on pageload, and every 5 minutes.
// Update applied-promocode if response changes, and reset/clear
// if no longer valid.
export function useValidatePromoCodeEffects() {
    const [{ promoCode, appliedPromoCode }, voucherDispatch] = useAtomReducer(voucherAtom);

    const promoCodeStatus = usePromocodeStatus(
        promoCode || appliedPromoCode?.coupon_code || undefined,
        undefined,
        undefined,
        60 * 5 * 1000, // Refetch every 5 minutes
    );

    useEffect(() => {
        if (!promoCode && !appliedPromoCode) return;

        // If `error` is set and is of type `BilberryApiError`, we know
        // that this is a response from the API, and not a timeout/broken
        // connection.
        if (promoCodeStatus.error instanceof BilberryApiError) {
            // Remove promoCode/appliedPromoCode since no longer valid.
            voucherDispatch(resetPromoCode());
            voucherDispatch(resetAppliedPromoCode());
        } else if (promoCodeStatus.data && !isEqual(promoCodeStatus.data, appliedPromoCode)) {
            // Update appliedPromoCode since status-value has changed.
            voucherDispatch(setAppliedPromoCode(promoCodeStatus.data));
        }
    }, [voucherDispatch, promoCodeStatus, promoCode, appliedPromoCode]);
    // (Yes, this useEffect-hook will also run directly after setting a new
    // promocode, but the `usePromocodeStatus` hook will dedupe (SWR) and
    // use cache instead of running an extra request, so this is fine)
}

// Check if giftcards are valid on pageload, and every 5 minutes.
// Update each individual giftcard if response changes, and remove if
// no longer valid.
export function useValidateGiftcardsEffects() {
    const [{ giftcards }, voucherDispatch] = useAtomReducer(voucherAtom);
    const [lastModified, setLastModified] = useState(0);
    const [triggerValue, setTriggerValue] = useState(0);
    const [didInitialRun, setDidInitialRun] = useState(
        // If we have giftcards on pageload, set false to provoke an
        // immediate poll-request. If no giftcards set to true, since
        // we don't need the immediate poll.
        giftcards.length ? false : true,
    );

    const timeInterval = 60 * 5 * 1000;

    // If giftcards-value changes, update last-modified value.
    useEffect(() => {
        setLastModified(giftcards.length ? Date.now() : 0);
    }, [giftcards, setLastModified]);

    // Update trigger-value periodically, to force the primary
    // useEffect below to run.
    useEffect(() => {
        const interval = setInterval(() => {
            setTriggerValue(Math.random());
        }, 5000); // Every 5 seconds

        return () => {
            clearInterval(interval);
        };
    }, [setTriggerValue]);

    useEffect(() => {
        if (
            !giftcards.length || // No giftcards
            (didInitialRun && // If has ran on pageload
                (!lastModified || // lastModified is 0
                    Date.now() - lastModified < timeInterval)) // Reached limit since modification
        )
            return;

        if (!didInitialRun) setDidInitialRun(true);

        setLastModified(Date.now());

        // Since we can't use await directly in useEffect, we use this proxy-function to
        // encapsulate the async stuff.
        const doValidation = async () => {
            let updates = false;
            const newValue = [...giftcards];

            for (let i = newValue.length - 1; i >= 0; i--) {
                const giftcard = newValue[i];

                try {
                    const status = await getGiftcardStatus(giftcard.id);

                    // If status is no longer valid, remove the giftcard.
                    if (!isGiftcardStatusValid(status)) {
                        updates = true;
                        newValue.splice(i, 1);
                        // If status-response has changed, update the giftcard.
                    } else if (!isEqual(giftcard, status)) {
                        updates = true;
                        newValue[i] = status;
                    }
                } catch (e) {
                    // If the error thrown is not an instance of BilberryApiError, rethrow it
                    // and abort.
                    if (!(e instanceof BilberryApiError)) throw e;

                    // Since this is an instance of BilberryApiError, thus not a 200 response,
                    // the giftcard is no longer valid so we remove it.
                    updates = true;
                    newValue.splice(i, 1);
                }
            }

            if (updates) voucherDispatch(setGiftcards(newValue));
        };

        doValidation();
    }, [giftcards, voucherDispatch, triggerValue, lastModified, didInitialRun, timeInterval]);
}
