import produce from 'immer';
import { atom } from 'ximple/atoms';
import { CheckoutInfo, MembershipUser, CartItem } from '@repo/types';
import { createInitialCheckoutInfoData } from '@repo/widget-utils/checkout-info-helper';
import { VERSION } from '@repo/widget-env/__autogen/env';
import { LocalStorageKey } from '../localstorage';

type State = CheckoutInfo | null;

type ResetAction = {
    type: 'reset';
    value?: undefined;
};

type SetInitAction = {
    type: 'setInit';
    value: { loggedInUser: MembershipUser | null; cart: CartItem[] };
};

type SetContactPersonAction = {
    type: 'setContactPerson';
    value: Partial<CheckoutInfo['contactPerson']>;
};

type SetCompanyInfoAction = {
    type: 'setCompanyInfo';
    value: Partial<CheckoutInfo['companyInfo']>;
};

type SetContactTypeAction = {
    type: 'setContactType';
    value: CheckoutInfo['contactType'];
};

type SetCopyFromContactPersonAction = {
    type: 'setCopyFromContactPerson';
    value: CheckoutInfo['copyFromContactPerson'];
};

type SetGuestListQuestionnaireAction = {
    type: 'setGuestListQuestionnaire';
    value: {
        cartItemId: keyof CheckoutInfo['guestListQuestionnaire'];
        guestKey: keyof CheckoutInfo['guestListQuestionnaire'][string];
        questionnaire: CheckoutInfo['guestListQuestionnaire'][string][string];
    };
};

type SetOrderQuestionnaireAction = {
    type: 'setOrderQuestionnaire';
    value: {
        cartItemId: keyof CheckoutInfo['orderQuestionnaire'];
        questionnaire: CheckoutInfo['orderQuestionnaire'][string];
    };
};

type Actions =
    | ResetAction
    | SetInitAction
    | SetContactPersonAction
    | SetCompanyInfoAction
    | SetContactTypeAction
    | SetCopyFromContactPersonAction
    | SetGuestListQuestionnaireAction
    | SetOrderQuestionnaireAction;

export const checkoutInfoAtom = atom<State, Actions>({
    initialValue: null,
    persistKey: LocalStorageKey.BILBERRY_CHECKOUT_INFO_NEW,
    appVersion: VERSION,
    update: produce((state: State, { type, value }: Actions): State => {
        if (type === 'setInit')
            return createInitialCheckoutInfoData(state, value.cart, value.loggedInUser);

        if (type === 'reset') return null;

        if (!state) return state;

        switch (type) {
            case 'setContactType':
                state.contactType = value;
                break;
            case 'setCopyFromContactPerson':
                state.copyFromContactPerson = value;
                break;
            case 'setContactPerson':
                state.contactPerson = {
                    ...state.contactPerson,
                    ...value,
                    email: value.email?.trim() ?? state.contactPerson.email,
                };
                break;
            case 'setCompanyInfo':
                state.companyInfo = {
                    ...state.companyInfo,
                    ...value,
                    email: value.email?.trim() ?? state.companyInfo.email,
                };
                break;
            case 'setGuestListQuestionnaire':
                state.guestListQuestionnaire[value.cartItemId][value.guestKey] = {
                    ...state.guestListQuestionnaire[value.cartItemId][value.guestKey],
                    ...value.questionnaire,
                };
                break;
            case 'setOrderQuestionnaire':
                state.orderQuestionnaire[value.cartItemId] = {
                    ...state.orderQuestionnaire[value.cartItemId],
                    ...value.questionnaire,
                };
                break;
        }

        return state;
    }),
});

export function reset(): ResetAction {
    return { type: 'reset' };
}

export function setInit(value: SetInitAction['value']): SetInitAction {
    return { type: 'setInit', value };
}

export function setContactPerson(value: SetContactPersonAction['value']): SetContactPersonAction {
    return { type: 'setContactPerson', value };
}

export function setCompanyInfo(value: SetCompanyInfoAction['value']): SetCompanyInfoAction {
    return { type: 'setCompanyInfo', value };
}

export function setContactType(value: SetContactTypeAction['value']): SetContactTypeAction {
    return { type: 'setContactType', value };
}

export function setCopyFromContactPerson(
    value: SetCopyFromContactPersonAction['value'],
): SetCopyFromContactPersonAction {
    return { type: 'setCopyFromContactPerson', value };
}

export function setGuestListQuestionnaire(
    value: SetGuestListQuestionnaireAction['value'],
): SetGuestListQuestionnaireAction {
    return { type: 'setGuestListQuestionnaire', value };
}

export function setOrderQuestionnaire(
    value: SetOrderQuestionnaireAction['value'],
): SetOrderQuestionnaireAction {
    return { type: 'setOrderQuestionnaire', value };
}

export function selectIsCompanyContact(state: State) {
    return state?.contactType === 'company';
}
