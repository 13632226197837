/* eslint-disable max-len */

import { RawTranslations } from '@repo/types';

const translations: RawTranslations = {
    show_cancellation_policy: 'Show Cancellation Policy',
    hide_cancellation_policy: 'Hide Cancellation Policy',
    departure: 'Departure',
    price: 'Price',
    difficulty: 'Difficulty',
    duration: 'Duration',
    starting_time: 'Starting time',
    about: 'About',
    book_now: 'Book now',
    book: 'Book',
    buy_as_gift: 'buy as gift',
    from: 'From',
    your_booking_reference_is: 'your booking reference is',
    booking_details_in_email: 'you will soon receive an email with further information',
    see_more_activities: 'See more activities',
    your_cart: 'Your cart',
    in_your_cart: 'In your cart',
    when_are_you_going: 'Pick a date and time',
    how_many_are_going: 'How many are going?',
    contact_details: 'Contact details',
    traveler: 'Traveler',
    extras: 'Extras',
    please_select: 'Please select',
    no_items_in_cart: 'There are no items in your shopping cart',
    payment: 'Payment',
    receipt: 'Receipt',
    do_you_have_any_comments_or_special_needs: 'Do you have any comments or special needs?',
    i_have_read_and_accepted_the: 'I have read and accepted the',
    privacy_policy: 'Privacy policy',
    terms_and_conditions: 'Terms and conditions',
    first_name: 'First name',
    last_name: 'Last name',
    phone_number: 'Phone number',
    dial_code: 'Dial code',
    email: 'Email',
    please_register: 'Please register',
    main_contact: 'Main contact',
    additional_information: 'Additional information',
    remove_cart_item: 'Remove cart item',
    back_to_webshop: 'Continue shopping',
    excl: 'excl.',
    vat: 'VAT',
    total: 'Total',
    payNow: 'Pay now',
    payLater: 'Pay later',
    only_deposit_amount_to_pay_now: {
        template: 'Only <NAME> in deposit to pay now.',
    } as any,
    next: 'Next',
    go_and_pay: 'Go and pay',
    print: 'Print',
    close: 'Close',
    checkout: 'Checkout',
    summary: 'Summary',
    cancel: 'Cancel',
    save: 'Save',
    select_time: 'Select time',
    selected_time: 'Selected time',
    time: 'Time',
    go_back: 'Go back',
    thank_you_for_booking_with_us: 'Thank you for booking with us!',
    please_fill_in_all_required_information: 'Please fill in all required information',
    error_occurred_when_creating_reservation: 'An error occurred when creating reservation',
    error_occurred_when_creating_giftcard: 'An error occurred when creating giftcard',
    couldnt_apply_promocode_is_the_code_correct:
        "Couldn't apply the promo code. Is the code correct?",
    choose_a_country: 'Choose a country',
    select_date: 'Select date',
    select_travelers: 'Select travelers',
    hourShort: 'h',
    dayShort: 'd',
    minuteShort: 'm',
    years: 'years',
    age: 'Age',
    more_information: 'More information',
    same_as_main_contact: 'Same as main contact',
    easy: 'Easy',
    medium: 'Medium',
    hard: 'Hard',
    please_select_date: 'Please select date',
    please_select_date_and_time: 'Please select date and time',
    name: 'Name',
    comment: 'Comment',
    submit_request: 'Submit request',
    any_special_requests_etc: 'Any special requests, food allergies...?',
    request_tour_comment: 'Describe your request',
    thank_you_for_your_request:
        'Thank you for your request, we will get back to you with lightning speed.',
    got_it: 'Got it!',
    continue_shopping: 'Continue shopping',
    continue_travelers: 'Travelers',
    please_provide_your_name: 'Please provide a name',
    please_provide_your_email: 'Please provide a valid email',
    please_provide_a_description: 'Please provide a description',
    an_error_occurred_when_submitting_your_request:
        'An error occurred when submitting your request. Please try again later.',
    private_tour: 'Private tour',
    read_more_and_book: 'Read more & book',
    read_more: 'Read more',
    prices_from: 'Prices from',
    about_the_trip: 'About the trip',
    prices: 'Prices',
    whats_included: "What's included",
    any: 'Any',
    duration_up_to_1_hour: 'Up to 1 hour',
    duration_1_2_hours: '1 - 2 hours',
    duration_2_6_hours: '2 - 6 hours',
    duration_6_12_hours: '6 - 12 hours',
    duration_12_24_hours: '12 - 24 hours',
    duration_1_2_days: '1 - 2 days',
    duration_more_than_2_days: 'More than 2 days',
    find_activities: 'Find activities',
    ok: 'OK',
    clear: 'Clear',
    filterActivities: 'Filter activities',
    hideFilters: 'Hide filters',
    shopMore: 'Shop more',
    noSearchResults: 'Sorry, we did not find any available activities matching your search.',
    update: 'Update',
    select_date_to_view_timeslots: 'Pick a date to view available time slots',
    your_reservation_timed_out: 'Your reservation timed out. Please try again.',
    aria_calendar_input_field_label: 'Calendar input. Press Enter to open.',
    aria_book_now_button: 'Open a window for booking this activity',
    aria_next_image: 'Next image',
    aria_previous_image: 'Previous image',
    aria_open_booking_widget: 'Open booking window',
    aria_close_booking_widget: 'Close booking window',
    aria_open_request_tour_widget: 'Open request tour window',
    aria_close_request_tour_widget: 'Close request tour window',
    aria_open_find_activity: 'Open find activity',
    aria_close_find_activity: 'Close find activity',
    aria_show_giftcard_input: 'Show gift card field',
    aria_hide_giftcard_input: 'Hide gift card field',
    fill_giftcard_code: 'Enter gift card code',
    aria_show_promocode_input: 'Show promo code field',
    aria_hide_promocode_input: 'Hide promo code field',
    aria_price_to_pay_summary: 'Summary of price to pay',
    fill_promocode: 'Enter promo code',
    request_tour: 'request tour',
    giftcard_message: 'message',
    giftcard_message_label: 'personal message to recipient (optional)',
    recipient_details: 'recipient details',
    gift_card: 'gift card',
    value: 'value',
    create: 'create',
    create_your_gift: 'create your gift card',
    your_giftcard_reference_is: 'your reference is',
    giftcard_voucher_and_receipt:
        'we have sent you a gift voucher ready for printing along with a receipt to:',
    giftcard_voucher: 'we have sent the gift voucher to:',
    giftcard_and_receipt: 'and a receipt to you at:',
    thank_you_for_your_purchase: 'thank you for your purchase!',
    valid_until: 'valid until',
    print_receipt: 'print receipt',
    select_gift_type: 'select gift type',
    please_select_gift_type: 'please select gift type',
    select_gift_print_description: 'recieve a printable PDF gift card in your inbox',
    select_gift_email_description: 'send a gift card by email directly to recipient',
    enter_giftcard_code: 'Enter a gift card code',
    enter_promo_code: 'Enter a promo code',
    apply: 'apply',
    giftcard_code_error: 'gift card code not valid',
    remaining_value: 'remaining value',
    giftcard_code_not_found: 'could not find giftcard with code:',
    giftcard_zero_balance: 'the gift card is already spent',
    giftcard_is_expired: 'the gift card is expired',
    purchased_giftcard: {
        self: 'printable gift card sent to you',
        recipient: 'printable gift card sent to recipient',
    },
    contact_information: {
        short: 'contact info',
        long: 'contact information',
    },
    minutes: {
        singular: 'min',
        plural: 'min',
        long: 'minute',
    },
    hours: {
        singular: 'hour',
        plural: 'hours',
    },
    days: {
        singular: 'day',
        plural: 'days',
    },
    children: {
        singular: 'child',
        plural: 'children',
    },
    adult: {
        singular: 'adult',
        plural: 'adults',
    },
    card: {
        singular: 'card',
        plural: 'cards',
    },
    too_few_travelers: 'Too few participants',
    too_few_travelers_quantity: {
        template: 'Minimum <COUNT:ticket:tickets> required this day',
    } as any,
    number_of_travelers_exceeded: {
        template: 'Max <COUNT:ticket:tickets> available this day',
    } as any,
    booking_is_closed: {
        template: 'Booking for <NAME> is now closed',
    } as any,
    rooms: {
        singular: 'room',
        plural: 'rooms',
    },
    person: {
        singular: 'person',
        plural: 'persons',
    },
    select_room: 'select room',
    change_room: 'change room',
    less_than: 'less than',
    year: {
        singular: 'year',
        plural: 'years',
    },
    select_age: 'select age',
    done: 'done',
    number_of_rooms: 'number of rooms',
    select_bed: 'select bed',
    room_info: 'room info',
    price_one_night_one_guest: {
        template: 'price for <COUNT:night:nights>, <COUNT:guest:guests>',
    } as any,
    one_night_one_guest: {
        template: '<COUNT:night:nights>, <COUNT:guest:guests>',
    } as any,
    price_nights: {
        template: 'price for <COUNT:night:nights>',
    } as any,
    night: {
        singular: 'night',
        plural: 'nights',
    },
    select_room_for_total_price: 'select rooms to get total price',
    find_rooms: 'find rooms',
    check_in_check_out: 'Check-in and Check-out',
    guest_and_rooms: 'Guests and rooms',
    no_accommodations_found:
        "We're sorry, but we couldn't find any rooms that match your search criteria. Have you considered adjusting your search parameters to find available rooms?",
    facilities_included: 'Facilities included',
    price_per_night: 'Price per night',
    season: 'Season',

    has_airconditioning: 'Airconditioning',
    has_balcony: 'Balcony',
    has_bicycles: 'Bicycles',
    has_boat: 'Boat',
    has_coffee_maker: 'Coffee maker',
    has_dishwasher: 'Dishwasher',
    has_dryer: 'Dryer',
    has_fireplace: 'Fireplace',
    has_garage: 'Garage',
    has_garden_furniture: 'Garden furniture',
    has_grill: 'Grill',
    has_kitchen: 'Kitchen',
    has_minibar: 'Minibar',
    has_mooring_space: 'Mooring space',
    has_pool: 'Pool',
    has_roomservice: 'Room Service',
    has_sauna: 'Sauna',
    has_tv: 'TV',
    has_washing_machine: 'Washing Machine',
    has_wifi: 'WiFi',
    is_breakfast_included: 'Breakfast',
    is_cancellation_included: 'Possibility for cancellation',
    is_pets_allowed: 'Pets allowed',
    is_smoking_allowed: 'Smoking allowed',
    is_towels_and_bedlines_included: 'Towels and bed liners',
    is_wheelchair_accessible: 'Accessible by wheelchair',
    wifi: 'WiFi',
    breakfast: 'Breakfast',
    accessible: 'Accessible',
    pets: 'Pets',
    kitchen: 'Kitchen',
    smoking: 'Smoking',
    unknown: 'Unknown',
    accommodation_cancellation_policy: '',
    accommodations: 'Accommodations',
    activities: 'Activities',
    address: 'address',
    city: 'city',
    post_code: 'post code',
    to: 'to',
    date_range_start: 'start',
    date_range_end: 'end',
    flag_of: 'flag of',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    sunday: 'sunday',
    today: 'today',
    tomorrow: 'tomorrow',
    january: 'january',
    february: 'february',
    march: 'march',
    april: 'april',
    may: 'may',
    june: 'june',
    july: 'july',
    august: 'august',
    september: 'september',
    october: 'october',
    november: 'november',
    december: 'december',
    few_left: 'few left',
    tickets_left: {
        template: '<COUNT:ticket:tickets> left',
    } as any,
    available: 'available',
    sold_out: 'sold out',
    upcoming_tours_title: 'Upcoming Tours',
    unavailable: 'Unavailable',
    notes_regarding_your_stay: 'Notes regarding your stay',
    notes_for_accommodation_name_with_guests: {
        template: 'Notes for <NAME> with <COUNT:guest:guests>',
    } as any,
    select_price_type: 'Select price type',
    finding_available_rooms: 'Finding available rooms',
    total_price: 'Total price',
    of: 'of',
    select_dates: 'Select dates',
    please_select_price_type: 'Please select price type',
    a_minimum_of_participants_is_required_to_book_this_product:
        'A minimum number of participants is required to book this product.',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: `A minimum of <COUNT> participants is required to book this product`,
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'A booking confirmation will be sent to your email address as soon as we have processed your order.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'A confirmation will be sent to your email address as soon as we have processed your order.',
    add: 'Add',
    amount: 'Amount',
    areYouAMemberOrHaveValueCard: 'Are you a member, or have a value card?',
    areYouSureYouWantToCancelYourBooking: 'Are you sure you want to cancel this booking?',
    areYouSureYouWantToCancelYourMembership: 'Are your sure you want to cancel your membership?',
    automaticallyRenewed: 'Automatically renewed',
    at: 'At',
    backToWebsite: 'Back to website',
    belayCertification: 'Belay certification',
    belayCertificationConsent: 'I have a belay certification',
    buyMembership: 'Buy membership',
    buyNow: 'Buy now',
    cancelled: 'Cancelled',
    canBeCancelledFrom: 'Can be cancelled from',
    choose_payment_method: 'Choose payment method',
    bookNow: 'Buy now',
    bookTimeslot: 'Buy tickets',
    cancellation: 'Cancellation',
    choose: 'Choose',
    chooseACountry: 'Choose a country',
    chooseCreditsType: 'Choose type of credits',
    chooseDateAndTime: 'Choose date and time',
    chooseNumberOfCredits: 'Choose number of credits',
    chooseStartDate: 'Choose start date',
    chooseType: 'Choose type',
    ticketCode: 'Order reference',
    completed: 'Completed',
    consents: 'Consents',
    contact: 'Contact',
    contactByEmail: 'Contact by Email',
    contactBySms: 'Contact by SMS',
    couldntApplyGiftcardIsTheCodeCorrect: "Couldn't apply the gift card. Is the code correct?",
    couldntApplyPromocodeIsTheCodeCorrect: "Couldn't apply the promo code. Is the code correct?",
    couldntGetCompanyInformation: "Couldn't get company information",
    couldntGetPaymentPlan: "Couldn't get payment plan. Please try refreshing the web page.",
    couldntGetPaymentPlanUsingPreviousPlan: "Couldn't get payment plan, using previous plan",
    couldntGetValueCards: "Couldn't get value cards",
    couldntGetValueCardProducts: "Couldn't get value card products",
    couldntGetProductPleaseTryAgain: "Couldn't get product, please try again",
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        "Couldn't open booking checkout, not all required booking information has been specified.",
    couldntOpenProfilePleaseSignInFirst: "Couldn't open profile, please sign in first.",
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        "Couldn't open value card checkout, not all required value card information has been specified.",
    country: 'Country',
    createAccount: 'Create account',
    credit: {
        singular: 'Credit',
        plural: 'Credits',
    },
    credits: 'Credits',
    creditsLeft: 'Credits left',
    creditDiscount: 'Credit discount',
    discount: 'Discount',
    free: 'Free',
    date: 'Date',
    delete: 'Delete',
    doYouHaveAnAccount: 'Do you have an account?',
    doYouHaveValueCards: 'Do you have an account, or any value cards?',
    doYouHaveBrattkort: 'Do you have Brattkort or belay certification?',
    doYouHaveClimbingCertification: 'Do you have a climbing certification?',
    edit: 'Edit',
    editTimeslot: 'Edit timeslot',
    failed: 'Failed',
    firstName: 'First name',
    giftcard: 'Gift card',
    goToMyPage: 'Go to my page',
    ifYouWantToDeleteYourProfile: 'If you want to delete your profile',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'You can buy tickets from the shop on this website.',
    yourOrderReferenceIs: 'Your order reference is',
    iHaveReadAndAccepted: 'I have read and accepted the',
    lastName: 'Last name',
    postalLocation: 'Location',
    location: 'Location',
    loggedInAs: 'Logged in as',
    login: 'Log in',
    logout: 'Log out',
    membershipCard: 'Membership',
    membershipNumber: 'Membership number',
    membershipSlashCredits: 'Membership / Credits',
    monthlyCharge: 'Monthly charge',
    myAddress: 'My address',
    myPage: 'My page',
    myProfile: 'My profile',
    nextCreateAccount: 'Next: Create account',
    no: 'No',
    noneSelected: 'None selected',
    noSelectedDate: 'No date selected',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        'Not all fields have been filled, please provide all fields.',
    noUserInfoFound: 'No user information found.',
    numberOfPersons: {
        singular: 'person',
        plural: 'persons',
        template: '<COUNT:person:persons>',
    } as any,
    pay: 'Pay',
    payWithCreditCard: 'Pay with credit card',
    phoneNumber: 'Phone number',
    pleaseChoose: 'Please choose',
    pleaseContact: 'Please contact',
    pleaseFillOutUserInfoInProfile: 'Please fill out user information in your profile.',
    pleaseLogInToBook: 'Please log in to buy with these',
    pleaseLogInToPurchase: 'Please log in and we will fill out your contact info for you',
    pleaseProvideAllValueCardInformation: 'Please provide all value card information',
    pleaseProvideProperBookingInformation: 'Please provide valid information',
    pleaseRegister: 'Please register',
    postalCodeAndLocation: 'Postal code / location',
    prefix: 'Prefix',
    privacyPolicy: 'Privacy policy',
    problemLoadingData: 'Error while loading data',
    processing: 'Processing',
    purchaseValueCard: 'Purchase value card',
    readMoreAbout: 'Read more about our',
    readMoreAndBook: 'Read more and buy',
    readMoreAndBuy: 'Read more and buy',
    refillCredits: 'Refill credits',
    returnToWebsite: 'Return to website',
    scanTicket: 'Scan ticket',
    signUp: 'Sign up',
    subtract: 'Subtract',
    shoesAndHarnessAlwaysIncluded: 'Shoes and harness always included.',
    shoesAndHarnessNotIncluded: 'Shoes and harness not included.',
    somethingWentWrongUpdatingUserInfo: 'Something went wrong updating user info.',
    termsOfUse: {
        template: 'Terms of use for <NAME>',
    } as any,
    upcomingBookings: 'Upcoming bookings',
    updateAddress: 'Update address',
    terminate: 'Terminate',
    thankYouForBookingWithUs: 'Thank you for booking with us.',
    thePrivacyPolicy: 'Privacy Policy',
    thankYouForShoppingWithUs: 'Thank you for shopping with us.',
    thisGiftcardIsEmpty: 'This giftcard is empty.',
    tHours: 'hrs',
    totalCredits: 'Total credits',
    totalNOK: 'Total NOK',
    updateBooking: 'Update booking',
    updateProfile: 'Update profile',
    useGiftcard: 'Use giftcard',
    useGiftcardOrPromocode: 'Use giftcard / promocode',
    validationNotANumber: 'Must be a number',
    validationMaxNumber: {
        template: 'No numbers above <NUM>',
    } as any,
    validationMinNumber: {
        template: 'No numbers below <NUM>',
    } as any,
    validForThePeriod: 'Valid for the period',
    validUntil: 'Valid until',
    validFrom: 'Valid from',
    valueCard: 'Value card',
    visitors: 'Visitors',
    withoutVat: 'Without VAT',
    yes: 'Yes',
    youDontHaveAnyUpcomingBookings: 'You do not have any upcoming bookings.',
    youDontHaveAnyValueCards: 'You dont have any value cards',
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        "You don't have a valid membership, do you wish to purchase one?",
    youMustSpecifyAValueCard: 'You must specify a valid value card.',
    yourReservationTimedOut: 'Your reservation timed out.',
    zipcode: 'Postal code',
    payByMembershipCard: 'Pay by membership card',
    payWith: 'Pay with',
    deleteMyProfile: 'Delete my profile',
    areYouSureYouWantToDeleteYourProfile: 'Are you sure you want to delete your profile?',
    deleteYourProfileInformation:
        'You are about to delete and disconnect your profile information from any bookings and purchased products. This action cannot be undone. Some information may be stored for legal reasons.',
    choosePaymentOtherMethods: 'Choose other payment methods',
    dontUseMembershipCardForThisBooking: 'Do not use membership card for this booking',
    nameNotProvided: 'Name is not provided',
    phoneNumberNotProvided: 'Phone number is not provided',
    emailNotProvided: 'Email is not provided',
    addressNotProvided: 'Address is not provided',
    promocode: 'Promo code',
    show_usage_terms: 'Show Usage Terms',
    hide_usage_terms: 'Hide Usage Terms',
    about_the_product: 'about the product',
    successful: 'Successful',
    selectProduct: 'Select Product',
    withCreditCard: 'With credit card',
    paymentMethod: 'Payment Method',
    noAvailableTimeslots: 'No available timeslots',
    orderReference: 'Order Reference',
    iWishToRecieveNewsletters: 'I wish to receive newsletters',
    mySite: 'My Site',
    pleaseConfirm: {
        template: 'Please confirm "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Please provide number of participants',
    pleaseSelectTheDateAndTimeOfYourBooking: 'Please select the date and time of your booking',
    pleaseSelectNumberOfCredits: 'Please select number of credits',
    pleaseSelectTypeOfCreditcard: 'Please select type of credit card',
    pleaseSelectAStartDate: 'Please select a start date',
    pleaseSelectAMembershipType: 'Please select a membership type',
    contactCustomerServiceToDeleteYourProfile: 'Contact customer service to delete your profile',
    inclInMembership: 'Incl. in membership',
    maxAmount: 'Max amount',
    checkIn: 'Check in',
    checkOut: 'Check out',

    anErrorOccurredDuringPayment: 'An error occurred during payment',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        'An error occurred, please try again by reloading the page. Error message',
    share: 'Share',
    copyLink: 'Copy link',
    howDoYouWantToShare: 'How do you want to share?',

    shoppingCart: 'Shopping cart',
    heresMyShoppingCart: "Here's my shopping cart",
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        'There is not enough capacity for the items in this cart',

    full: 'Full',
    no_available_capacity_for_this_tour: 'No available capacity for this tour',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        "One or more activities don't have enough capacity",

    pick_a_date: 'Pick a date',
    package: 'Package',
    packages: 'Packages',
    customize_activities: 'Customize activities',
    check_in_at: 'Check in at',
    activity_starts_at: 'Activity starts at',
    other_times_are_available: 'Other times are available',
    press_to_select_other_options: 'Press to select other options',
    unavailableForSelectedTime: 'Unavailable at the selected time',
    order_status_awaiting_payment: 'Processing payment',
    order_status_payment_failed: 'payment failed',
    awaiting_payment_info:
        'Processing payment... usually doesn`t take more than a few a few minutes.',
    cancel_membership: 'Cancel membership',
    update_subscription_payment_details: 'Update payment details',
    payment_failed_info:
        'There seems to be a problem with your payment. You could try updating the payment details.',
    retry_subscription_payment: 'Retry payment now',

    entrance: 'Entrance',
    related_products: 'Related products',
    some_tickets_are_unavailable_the_selected_day: 'Some tickets are unavailable the selected day',
    company: 'Company',
    company_name: 'Company name',
    org_number: 'Organization number',
    pay_with_invoice: 'Pay with invoice',
    continueWithRebooking: 'Continue with rebooking',
    searchAndBookAccommodations: 'Search and book accommodation',
    selectNumberOfGuests: 'Select number of guests',
    checkAvailability: 'Check availability',
    updateAvailability: 'Update availability',
    you_will_have_to_switch_rooms_during_your_stay:
        'You will have to switch rooms during your stay. Contact us to proceed with the booking.',
    selected: 'Selected',
    remove: 'Remove',
    aria_increase: 'Increase',
    aria_decrease: 'Decrease',
    promo_code_not_supported_for_gift_cards: 'The promo code is not supported for gift cards',
    select_products: 'Select products',
    change_selected_products: 'Change selected products',
    cartCompatibilityWarning:
        'There are products in your cart that are not compatible with this product. Please purchase them separately, or empty your cart to continue.',
    membershipBookingDisabledWarning:
        'Membership Booking is disabled on this website, but this product requires it. This is most likely a misconfiguration. Please contact customer service.',
    see_available_tickets: 'See available tickets',
};

export default translations;
