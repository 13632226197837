import CancelRounded from '@mui/icons-material/CancelRounded';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';
import {
    Box,
    Button,
    capitalize,
    Collapse,
    FormHelperText,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment, useState } from 'react';
import {
    localeAtom,
    useLocale,
    formatCurrency,
    getLocaleNumberFormatTwoDecimals,
} from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { isGiftcardStatusValid, GiftcardValidationResult } from '@repo/widget-utils/voucherUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';
import ClearIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { BilberryGiftcardStatus, AppliedGiftCard } from '@repo/types';
import { getGiftcardStatus } from '@repo/widget-utils/services/fetchers/product';

interface Props {
    onApplyGiftcard: (giftcard: BilberryGiftcardStatus) => void;
    appliedGiftcards: AppliedGiftCard[];
    onError: (message: string) => void;
    onRemoveAppliedGiftcard: (giftcard: AppliedGiftCard) => void;
}

export default function SummaryApplyGiftCardPayments(props: Props): JSX.Element {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(props.appliedGiftcards.length > 0);
    const [giftcardValidationErrorMessage, setGiftcardValidationErrorMessage] = useState('');

    return (
        <Box borderBottom={`1px solid ${theme.palette.grey[400]}`}>
            <ExpanderHeader expanded={expanded} setExpanded={setExpanded}></ExpanderHeader>
            <Collapse
                in={expanded}
                sx={{ paddingBottom: theme.spacing(2) }}
                timeout="auto"
                unmountOnExit
            >
                <Fragment>
                    <GiftcardApplier
                        onError={props.onError}
                        onApplyGiftcard={props.onApplyGiftcard}
                        giftcardValidationErrorMessage={giftcardValidationErrorMessage}
                        setGiftcardValidationErrorMessage={setGiftcardValidationErrorMessage}
                    ></GiftcardApplier>

                    <AppliedGiftcardsSummary
                        appliedGiftcards={props.appliedGiftcards}
                        onRemoveAppliedGiftcard={props.onRemoveAppliedGiftcard}
                    ></AppliedGiftcardsSummary>
                </Fragment>
            </Collapse>
        </Box>
    );
}

async function tryApplyGiftcard(
    giftcardId: string,
    onApplyGiftcard: (giftcard: BilberryGiftcardStatus) => void,
    setGiftcardValidationErrorMessage: React.Dispatch<React.SetStateAction<string>>,
) {
    const { t } = localeAtom.subject.value;
    try {
        const giftcardStatus = await getGiftcardStatus(giftcardId);

        switch (isGiftcardStatusValid(giftcardStatus)) {
            case GiftcardValidationResult.ZERO_BALANCE:
                setGiftcardValidationErrorMessage(capitalize(t.giftcard_zero_balance));
                return;
            case GiftcardValidationResult.EXPIRED:
                setGiftcardValidationErrorMessage(capitalize(t.giftcard_is_expired));
                return;
        }

        onApplyGiftcard(giftcardStatus);
    } catch (error) {
        setGiftcardValidationErrorMessage(capitalize(t.giftcard_code_not_found) + ' ' + giftcardId);
    }
}

function ExpanderHeader(props: {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
}): JSX.Element {
    const customizations = useCustomizations();
    const theme = useTheme();
    const { t } = useLocale();
    const { expanded, setExpanded } = props;

    return (
        <Grid
            container
            onClick={() => {
                setExpanded(!expanded);
            }}
            alignItems="center"
        >
            <Button
                color="primary"
                sx={{ padding: 0, color: customizations.linkColor }}
                aria-label={!expanded ? t.aria_show_giftcard_input : t.aria_hide_giftcard_input}
            >
                {capitalize(t.enter_giftcard_code)}
            </Button>
            <IconButton
                tabIndex={-1}
                sx={[
                    {
                        transition: theme.transitions.create('transform', {
                            duration: theme.transitions.duration.standard,
                        }),
                        '& .MuiSvgIcon-root': {
                            color: customizations.linkColor,
                        },
                        color: customizations.linkColor,
                    },
                    expanded && { transform: 'rotate(180deg)' },
                ]}
            >
                <KeyboardArrowDownRounded />
            </IconButton>
        </Grid>
    );
}

function GiftcardApplier(props: {
    onApplyGiftcard: (giftcard: BilberryGiftcardStatus) => void;
    onError: (message: string) => void;
    giftcardValidationErrorMessage: string;
    setGiftcardValidationErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}) {
    const customizations = useCustomizations();
    const theme = useTheme();
    const { t } = useLocale();
    const [inputValue, setInputValue] = useState('');

    const handleApplyGiftcard = (giftcard: BilberryGiftcardStatus) => {
        setInputValue('');
        props.onApplyGiftcard(giftcard);
    };

    const handleButtonClick = async () => {
        if (inputValue.length <= 0) return;

        await tryApplyGiftcard(
            inputValue,
            handleApplyGiftcard,
            props.setGiftcardValidationErrorMessage,
        );
    };

    const endAdornment =
        inputValue !== '' ? (
            <Box
                sx={{
                    '& .MuiIconButton-root': {
                        padding: 0,
                    },
                }}
            >
                <IconButton
                    sx={{
                        padding: 0,
                        '& .MuiSvgIcon-root': {
                            color: customizations.linkColor,
                        },
                    }}
                    onClick={() => {
                        props.setGiftcardValidationErrorMessage('');
                        setInputValue('');
                    }}
                >
                    <CancelRounded fontSize="small" />
                </IconButton>
            </Box>
        ) : undefined;

    return (
        <Fragment>
            <Grid container wrap="nowrap" justifyContent="space-between">
                <Grid item>
                    <TextField
                        error={!!props.giftcardValidationErrorMessage}
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            marginRight: theme.spacing(1),
                            width: '264px',
                            [`@media screen and (max-width: ${768}px)`]: {
                                width: '232px',
                            },
                        }}
                        onChange={(e) => {
                            props.setGiftcardValidationErrorMessage('');
                            setInputValue(e.currentTarget.value);
                        }}
                        onKeyDown={(ev: React.KeyboardEvent) => {
                            if (ev.code?.toLowerCase() === 'enter') {
                                handleButtonClick();
                            }
                        }}
                        variant="outlined"
                        value={inputValue}
                        color="secondary"
                        placeholder={t.fill_giftcard_code}
                        InputProps={{
                            endAdornment: endAdornment,
                        }}
                        size="small"
                    />
                    {!!props.giftcardValidationErrorMessage && (
                        <FormHelperText sx={{ color: theme.palette.error.main }}>
                            {props.giftcardValidationErrorMessage}
                        </FormHelperText>
                    )}
                </Grid>
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: theme.spacing(1),
                        borderColor: theme.palette.primary.main,
                        height: theme.spacing(5),
                    }}
                    onClick={handleButtonClick}
                    aria-label={t.enter_giftcard_code}
                >
                    {t.apply.toUpperCase()}
                </Button>
            </Grid>
        </Fragment>
    );
}

function AppliedGiftcardsSummary(props: {
    appliedGiftcards: AppliedGiftCard[];
    onRemoveAppliedGiftcard: (giftcard: AppliedGiftCard) => void;
}) {
    return (
        <List>
            {props.appliedGiftcards.map((giftcard, index) => (
                <AppliedGiftcardSummary
                    key={index}
                    giftcard={giftcard}
                    onRemoveAppliedGiftcard={props.onRemoveAppliedGiftcard}
                ></AppliedGiftcardSummary>
            ))}
        </List>
    );
}

function AppliedGiftcardSummary(props: {
    giftcard: AppliedGiftCard;
    onRemoveAppliedGiftcard: (giftcard: AppliedGiftCard) => void;
}) {
    const theme = useTheme();
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);

    const { priceReduction } = props.giftcard;
    const giftCardRemaining = props.giftcard.giftcardStatus.balance - props.giftcard.priceReduction;

    return (
        <ListItem
            disableGutters
            secondaryAction={
                <Typography color="secondary">
                    -{getLocaleNumberFormatTwoDecimals(locale, priceReduction)}
                </Typography>
            }
        >
            <ListItemText
                primary={
                    <>
                        <Typography color="primary">
                            {props.giftcard.giftcardStatus.custom_code ??
                                props.giftcard.giftcardStatus.id}
                            <IconButton
                                aria-label={`${capitalize(t.remove)}`}
                                color="secondary"
                                sx={{ p: 0.25 }}
                                size="small"
                                onClick={() => {
                                    props.onRemoveAppliedGiftcard(props.giftcard);
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Typography>
                    </>
                }
                secondary={
                    <FormHelperText sx={{ color: theme.palette.grey[600], mt: 0 }}>
                        {capitalize(t.remaining_value)}:{' '}
                        {formatCurrency(locale, currency, giftCardRemaining)}
                    </FormHelperText>
                }
            ></ListItemText>
        </ListItem>
    );
}
