import ClearIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { Box, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useLocale } from '@repo/i18n';
import { removeFromCartEvent } from 'src/state/cart/cart.reducer';
import { cartAtom } from 'src/state/cart/cartAtom';
import { CartItem } from '@repo/types';
import { capitalize } from '@repo/common-utils/TextUtils';
import {
    getCartItemId,
    getCartItemSummaryDateInformation,
    isAccomodationOrNightsCartItem,
    isActivityCartItem,
    isExtraCartItem,
    isPackageCartItem,
    isTimeslotCartItem,
} from '@repo/widget-utils/cart/cartUtils';
import { getCartItemDisplayTitle } from '@repo/widget-utils/display-helper';
import SummaryPriceDetailsForCartItem from './SummaryPriceDetailsForCartItem';
import { useCallback, useState } from 'react';
import { BookingContextProvider } from 'src/widgets/BookingContext';
import { formatTime } from '@repo/widget-utils/DateHelpers';
import { tzdate } from '@repo/tzdate';
import { EditActivityModal } from './edit-activity/EditActivity';

interface IProps {
    isCheckoutSummary: boolean;
    cartItems: CartItem[];
    invertedColorsClass?: Record<string, unknown>;
    isConfirmationPage?: boolean;
    isPaymentPage?: boolean;
    isUsingPaymentPlan: boolean;
    isLoadingPrices: boolean;
}

export default function SummaryBooking(props: IProps): JSX.Element {
    const {
        cartItems,
        isCheckoutSummary,
        invertedColorsClass,
        isConfirmationPage,
        isPaymentPage,
        isUsingPaymentPlan,
    } = props;
    const { t } = useLocale();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [editCartItem, setEditCartItem] = useState<CartItem | null>(null);

    const extraCartItems = cartItems.filter(isExtraCartItem);

    // Filter out the extra cart items, to avoid displaying them twice
    // TODO: maybe this can be done in a more elegant way. But need more insights on the data structure first
    const remainingCartItems = cartItems.filter((item) => !extraCartItems.includes(item));
    const activityCartItems = remainingCartItems.filter(isActivityCartItem);
    const accommodationCartItems = remainingCartItems.filter(isAccomodationOrNightsCartItem);
    const packageCartItems = remainingCartItems.filter(isPackageCartItem);
    const timeslotCartItems = remainingCartItems.filter(isTimeslotCartItem);

    const removeCartItem = useCallback((item: CartItem) => {
        cartAtom.update(removeFromCartEvent(item));
    }, []);

    const onEditClick = useCallback(
        (cartItem: CartItem) => {
            const showEdit =
                isCheckoutSummary && !isConfirmationPage && !cartItem.pkg && !isUsingPaymentPlan;

            if (!showEdit) return undefined;

            const onEdit = (e: React.MouseEvent) => {
                if (e.currentTarget instanceof HTMLElement) {
                    setAnchorEl(e.currentTarget);
                    setEditCartItem(cartItem);
                }
            };

            return onEdit;
        },
        [isCheckoutSummary, isConfirmationPage, isUsingPaymentPlan],
    );

    return (
        <Box>
            {(
                [
                    [accommodationCartItems, t.accommodations],
                    [activityCartItems, t.activities],
                    [packageCartItems, t.packages],
                    [timeslotCartItems, t.entrance],
                    [extraCartItems, t.extras],
                ] as const
            ).map(([items, tKey], i) =>
                items.length === 0 ? null : (
                    <Grid mb={theme.spacing(4)} key={i}>
                        <Typography variant="h5" color="textSecondary" sx={invertedColorsClass}>
                            {tKey}
                        </Typography>
                        {items.map((item) => (
                            <SummaryBookingItem
                                key={getCartItemId(item)}
                                item={item}
                                invertedColorsClass={invertedColorsClass}
                                onClearClick={
                                    isConfirmationPage || isPaymentPage
                                        ? undefined
                                        : () => {
                                              removeCartItem(item);
                                          }
                                }
                                onEditClick={onEditClick(item)}
                                isLoadingPrices={props.isLoadingPrices}
                            />
                        ))}
                    </Grid>
                ),
            )}

            {Boolean(anchorEl) && editCartItem && (
                <EditModal
                    cartItem={editCartItem}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setAnchorEl(null);
                        setEditCartItem(null);
                    }}
                ></EditModal>
            )}
        </Box>
    );
}

interface SummaryBookingItemProps {
    item: CartItem;
    invertedColorsClass?: Record<string, unknown>;
    onClearClick?: () => void;
    onEditClick?: (e: React.MouseEvent) => void;
    isLoadingPrices: boolean;
}

function SummaryBookingItem(props: SummaryBookingItemProps): JSX.Element {
    return (
        <Box>
            <SummaryHeaderForCartItem
                cartItem={props.item}
                invertedColorsClass={props.invertedColorsClass}
                onClearClick={props.onClearClick}
            ></SummaryHeaderForCartItem>

            <SummaryPriceDetailsForCartItem
                invertedColorsClass={props.invertedColorsClass}
                cartItem={props.item}
                onEditClick={props.onEditClick}
                isLoadingPrices={props.isLoadingPrices}
            />
        </Box>
    );
}

function EditModal(props: {
    anchorEl: HTMLElement | null;
    cartItem: CartItem;
    onClose: () => void;
    invertedColorsClass?: Record<string, unknown>;
}): JSX.Element {
    const initialSelectedTimeSlot = {
        label: formatTime(tzdate(props.cartItem.products[0].start)),
        sublabel: formatTime(tzdate(props.cartItem.products[0].end)),
        product: props.cartItem.products[0],
        products: props.cartItem.products[0]?.timeslots ?? [],
    };

    return (
        <BookingContextProvider
            initialTicketOptions={props.cartItem.ticketOptions}
            initialProducts={props.cartItem.products}
            initialTimeSlot={initialSelectedTimeSlot}
        >
            <EditActivityModal
                cartItem={props.cartItem}
                invertedColorsClass={props.invertedColorsClass}
                anchorEl={props.anchorEl}
                onClose={props.onClose}
            />
        </BookingContextProvider>
    );
}

function SummaryHeaderForCartItem(props: {
    cartItem: CartItem;
    invertedColorsClass?: Record<string, unknown>;
    onClearClick?: () => void;
}) {
    const { t } = useLocale();
    const theme = useTheme();

    const showTime = props.cartItem.products.length > 1 || props.cartItem.products[0]?.showTime;

    return (
        <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-between"
            borderBottom={`1px solid ${theme.palette.grey[400]}`}
            margin={theme.spacing(1, 0, 1.5, 0)}
        >
            <Stack justifyContent={showTime ? 'flex-end' : 'center'}>
                <Typography
                    variant="h6"
                    align="left"
                    color="textSecondary"
                    sx={props.invertedColorsClass}
                >
                    {getCartItemDisplayTitle(props.cartItem)}
                </Typography>
                {showTime && (
                    <Typography align="left" color="textSecondary" sx={props.invertedColorsClass}>
                        {getCartItemSummaryDateInformation(props.cartItem)}
                    </Typography>
                )}
            </Stack>
            {props.onClearClick && (
                <IconButton
                    aria-label={`
                            ${capitalize(t.remove_cart_item)}${' '}
                            ${getCartItemDisplayTitle(props.cartItem)}
                        `}
                    color="secondary"
                    sx={{
                        height: 'fit-content',
                        marginRight: '-0.5em',
                        ...props.invertedColorsClass,
                    }}
                    onClick={props.onClearClick}
                >
                    <ClearIcon />
                </IconButton>
            )}
        </Grid>
    );
}
