import { ReactNode } from 'react';
import { useAtom } from 'ximple';
import {
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { getCartItemId } from '@repo/widget-utils/cart/cartUtils';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { cartAtom } from 'src/state/cart/cartAtom';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import {
    checkoutInfoAtom,
    setContactType,
    setContactPerson,
    setCompanyInfo,
} from 'src/state/checkout-info/checkoutInfoAtom';
import CheckoutInfoQuestionnaires from './CheckoutInfoQuestionnaires';
import ContactPersonForm from './ContactPersonForm';
import CompanyInfoForm from './CompanyInfoForm';

type Props = {
    showErrors: boolean;
    showAddressFields: boolean;
};

export default function CheckoutInfoView(props: Props): ReactNode {
    const { t } = useLocale();
    const theme = useTheme();
    const [cartItems] = useAtom(cartAtom);
    const configuration = useConfigurations();
    const [checkoutInfo, dispatchCheckoutInfo] = useAtomReducer(checkoutInfoAtom);

    if (!checkoutInfo) return null;

    return (
        <Stack
            color={theme.palette.text.primary}
            fontFamily={theme.typography.fontFamily as ResponsiveStyleValue<any>}
            fontSize={theme.typography.fontSize}
            pt={0}
            minWidth="300px"
            width="100%"
            maxWidth="440px"
            gap={1}
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& > *': {
                        marginTop: theme.spacing(1),
                    },
                    backgroundColor: 'white',
                    width: '100%',
                    marginTop: theme.spacing(2),
                }}
                variant="outlined"
            >
                {configuration.enableInvoicePayment && (
                    <RadioGroup
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            justifyContent: 'center',
                        }}
                    >
                        <FormControl>
                            <FormControlLabel
                                value="personal"
                                control={<Radio />}
                                label={capitalize(t.person.singular)}
                                checked={checkoutInfo.contactType === 'person'}
                                onChange={() => {
                                    dispatchCheckoutInfo(setContactType('person'));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                value="company"
                                control={<Radio />}
                                label={t.company}
                                checked={checkoutInfo.contactType === 'company'}
                                onChange={() => {
                                    dispatchCheckoutInfo(setContactType('company'));
                                }}
                            />
                        </FormControl>
                    </RadioGroup>
                )}
                {checkoutInfo.contactType === 'company' ? (
                    <Company {...{ ...props }} />
                ) : (
                    <Personal {...{ ...props }} />
                )}
            </Paper>

            {cartItems.map((cartItem) => (
                <CheckoutInfoQuestionnaires
                    key={getCartItemId(cartItem)}
                    isCompany={checkoutInfo.contactType === 'company'}
                    showErrors={props.showErrors}
                    cartItem={cartItem}
                />
            ))}
        </Stack>
    );
}

function Personal(props: Props) {
    const { t } = useLocale();
    const [checkoutInfo, dispatchCheckoutInfo] = useAtomReducer(checkoutInfoAtom);

    if (!checkoutInfo) return null;

    return (
        <>
            <Typography color="secondary" variant="h6" mt={0}>
                {capitalize(t.main_contact)}
            </Typography>

            <ContactPersonForm
                contactPerson={checkoutInfo.contactPerson}
                onChange={(change) => {
                    dispatchCheckoutInfo(setContactPerson(change));
                }}
                showErrors={props.showErrors}
                showAddressFields={props.showAddressFields}
            />
        </>
    );
}

function Company(props: Props) {
    const { t } = useLocale();
    const [checkoutInfo, dispatchCheckoutInfo] = useAtomReducer(checkoutInfoAtom);

    if (!checkoutInfo) return null;

    return (
        <>
            <Typography color="secondary" variant="h6" mt={0}>
                {capitalize(t.company)}
            </Typography>

            <CompanyInfoForm
                companyInfo={checkoutInfo.companyInfo}
                onChange={(change) => {
                    dispatchCheckoutInfo(setCompanyInfo(change));
                }}
                showErrors={props.showErrors}
            />
        </>
    );
}
