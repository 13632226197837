import { useEffect } from 'react';
import { useAtom } from 'ximple';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import { cartAtom } from '../cart/cartAtom';
import {
    voucherAtom,
    resetAppliedPromoCode,
    resetGiftcards,
    resetIgnoredValueCards,
    resetPromoCode,
    selectHasIgnoredValueCards,
} from '../voucher/voucherAtom';

export function useVoucherEffects() {
    const [cart] = useAtom(cartAtom);
    const [voucher, voucherDispatch] = useAtomReducer(voucherAtom);
    const { promoCode, giftcards, appliedPromoCode } = voucher;

    useEffect(() => {
        // If cart is empty, we will reset promo-code and giftcards.
        if (cart.length !== 0) return;

        if (promoCode) voucherDispatch(resetPromoCode());

        if (appliedPromoCode) voucherDispatch(resetAppliedPromoCode());

        if (giftcards.length) voucherDispatch(resetGiftcards());

        if (selectHasIgnoredValueCards(voucher)) voucherDispatch(resetIgnoredValueCards());
    }, [cart, appliedPromoCode, giftcards]);
}
