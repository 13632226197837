import produce from 'immer';
import { atom } from 'ximple';
import { MembershipIntentMultiResponse } from '@repo/types';

type State = {
    isUsingPaymentPlan: boolean;
    isLoadingPrices: boolean;
    paymentPlanResponse: MembershipIntentMultiResponse | null;
};

type SetPaymentPlanResponseAction = {
    type: 'setPaymentPlanResponse';
    value: State['paymentPlanResponse'];
};

type SetIsUsingPaymentPlanAction = {
    type: 'setIsUsingPaymentPlan';
    value: State['isUsingPaymentPlan'];
};

type SetIsLoadingPricesAction = {
    type: 'setIsLoadingPrices';
    value: State['isLoadingPrices'];
};

type Actions =
    | SetPaymentPlanResponseAction
    | SetIsUsingPaymentPlanAction
    | SetIsLoadingPricesAction;

export const paymentPlanAtom = atom<State, Actions>({
    initialValue: {
        isUsingPaymentPlan: false,
        isLoadingPrices: false,
        paymentPlanResponse: null,
    },
    update: produce((state: State, { type, value }: Actions): State => {
        switch (type) {
            case 'setPaymentPlanResponse':
                state.paymentPlanResponse = value;
                break;
            case 'setIsUsingPaymentPlan':
                state.isUsingPaymentPlan = value;
                break;
            case 'setIsLoadingPrices':
                state.isLoadingPrices = value;
                break;
        }

        return state;
    }),
});

export function setPaymentPlanResponse(
    value: SetPaymentPlanResponseAction['value'],
): SetPaymentPlanResponseAction {
    return { type: 'setPaymentPlanResponse', value };
}

export function setIsUsingPaymentPlan(
    value: SetIsUsingPaymentPlanAction['value'],
): SetIsUsingPaymentPlanAction {
    return { type: 'setIsUsingPaymentPlan', value };
}

export function setIsLoadingPrices(
    value: SetIsLoadingPricesAction['value'],
): SetIsLoadingPricesAction {
    return { type: 'setIsLoadingPrices', value };
}
