import { Box, Button, Stack, ThemeProvider, Typography, useTheme } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useAtom } from 'ximple';
import { cartAtom } from 'src/state/cart/cartAtom';
import Summary from 'src/components/domain/summary/Summary';
import { useLocale } from '@repo/i18n';
import {
    CustomizationsContext,
    useCustomizations,
} from 'src/components/utils/theme/customizations';
import { ButtonDisplay } from './ButtonDisplay';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { ProductListBookable } from 'src/widgets/activities/product/product-list-bookable/ProductListBookable';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { MUITheme } from 'src/components/utils/theme/Theme';
import { useCartCheckoutContext } from '../CartCheckoutContext';

type RelatedProductsViewProps = {
    onNextClicked: () => void;
};

export function RelatedProductsView({ onNextClicked }: RelatedProductsViewProps) {
    const { t } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();
    const [cartItems] = useAtom(cartAtom);
    const { activeTab, relatedProductIds, largeScreen, isMakingReservation, idxOpts } =
        useCartCheckoutContext();
    const { hasExtras } = idxOpts;

    useWidgetEventEffect(() => ({
        eventType: 'checkoutStep',
        checkoutStep: 'RelatedProducts' as any,
        cartItems: cartItems,
    }));

    useEffect(() => {
        if (relatedProductIds.length === 0) {
            onNextClicked();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relatedProductIds]);

    useEffect(() => {
        const oldValue = configurationAtom.subject.value.skipBasketOnBook;
        configurationAtom.update({
            ...configurationAtom.subject.value,
            skipBasketOnBook: true,
        });
        return () => {
            configurationAtom.update({
                ...configurationAtom.subject.value,
                skipBasketOnBook: oldValue,
            });
        };
    }, []);

    const newCustomizations = {
        ...customizations,
        h2Size: '22px',
        bodySize: '14px',
        productListPadding: 0,
        productCardColor: theme.palette.grey[50],
        productCardTextColor: theme.palette.text.secondary,
        productCardPrimaryColor: theme.palette.primary.main,
        productCardPrimaryColorContrast: theme.palette.primary.contrastText,
        productCardIconColor: theme.palette.text.secondary,
    };

    return (
        <Fragment>
            {cartItems.length === 0 && (
                <Typography color="textSecondary" variant="h4">
                    {t.no_items_in_cart}
                </Typography>
            )}
            {cartItems.length > 0 && (
                <Stack
                    justifyContent={largeScreen ? 'space-between' : 'center'}
                    alignItems={largeScreen ? 'flex-start' : 'center'}
                    direction={largeScreen ? 'row' : 'column-reverse'}
                    gap={3}
                >
                    {!largeScreen && (
                        <Box
                            maxWidth="440px"
                            width="100%"
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    marginBottom: theme.spacing(3),
                                },
                            }}
                        >
                            <Button
                                sx={{ float: 'right' }}
                                color="primary"
                                variant={customizations.primaryButtonStyle}
                                disabled={isMakingReservation}
                                onClick={onNextClicked}
                            >
                                <ButtonDisplay
                                    isMakingReservation={isMakingReservation}
                                    buttonText={hasExtras ? t.extras : t.contact_details}
                                />
                            </Button>
                        </Box>
                    )}
                    <Stack mt={2} mb={4} width="100%">
                        <CustomizationsContext.Provider value={newCustomizations}>
                            <ThemeProvider theme={MUITheme(newCustomizations)}>
                                <ProductListBookable
                                    cardBorder={`1px solid ${theme.palette.grey[200]}`}
                                    cardBorderRadius={theme.shape.borderRadius}
                                    orientation="landscape"
                                    hideImageIfMissing
                                    hideReadMore
                                    productCatalogIds={relatedProductIds}
                                    productCollectionId={null}
                                />
                            </ThemeProvider>
                        </CustomizationsContext.Provider>
                    </Stack>
                    <Summary
                        showShare
                        hideGoPay={!largeScreen}
                        isCheckoutSummary={true}
                        isMakingReservation={isMakingReservation}
                        goPayClicked={onNextClicked}
                        nextButtonText={hasExtras ? t.extras : t.contact_details}
                        activeTab={activeTab}
                    />
                </Stack>
            )}
        </Fragment>
    );
}
