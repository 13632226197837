import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useAtom } from 'ximple';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import useCartItemsWithMembershipAddedOrRemoved from 'src/hooks/domain/cart/useCartItemsWithMembershipAddedOrRemoved';
import { cartAtom } from './cartAtom';
import { addOrReplaceCartEvent, validateEvent } from './cart.reducer';
import { paymentPlanAtom } from '../payment-plan/paymentPlanAtom';

export function useCartEffects() {
    const [cart, cartDispatch] = useAtomReducer(cartAtom);
    const [initialValidate, setInitialValidate] = useState(false);

    useEffect(() => {
        // Immediately validate cart on pageload.
        if (!initialValidate) {
            cartDispatch(validateEvent());
            setInitialValidate(true);
        }

        // Validate the cart every 10 seconds (unless empty)
        const interval = setInterval(() => {
            if (cart.length === 0) return;

            cartDispatch(validateEvent);
        }, 10 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [cart.length, cartDispatch, initialValidate, setInitialValidate]);
}

export function useCartMembershipEffects() {
    const [cart, cartDispatch] = useAtomReducer(cartAtom);
    const [{ isUsingPaymentPlan, paymentPlanResponse }] = useAtom(paymentPlanAtom);

    const cartItemsMembership = useCartItemsWithMembershipAddedOrRemoved(
        cart,
        isUsingPaymentPlan,
        paymentPlanResponse ?? null,
    );

    useEffect(() => {
        const plans = isUsingPaymentPlan && paymentPlanResponse;

        if (!plans || isEqual(cart, cartItemsMembership)) return;

        cartDispatch(addOrReplaceCartEvent(cartItemsMembership));
    }, [cart, cartDispatch, isUsingPaymentPlan, paymentPlanResponse, cartItemsMembership]);
}
