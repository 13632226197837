import {
    getCartItemId,
    removeInvalidCartItems,
    removeRelatedExtras,
} from '@repo/widget-utils/cart/cartUtils';
import {
    CartItem,
    Package,
    ProductInstance,
    TicketOptionWithQuantity,
    TicketType,
} from '@repo/types';
import { cartAtom } from './cartAtom';
import { TZDate } from '@repo/tzdate';

interface IAddEvent {
    type: 'ADD';
    data: CartItem;
}

interface IRemoveEvent {
    type: 'REMOVE';
    data: number;
}

interface IRemoveByIdEvent {
    type: 'REMOVE_BY_ID';
    data: string;
}

interface IClearEvent {
    type: 'CLEAR';
}

interface IValidateEvent {
    type: 'VALIDATE';
}

interface IInitializeEvent {
    type: 'INITIALIZE';
    data: CartItem[];
}

interface IAddOrReplaceCartItemsEvent {
    type: 'ADD_OR_REPLACE_CART_ITEMS';
    data: CartItem[];
}

export type CartEvents =
    | IAddEvent
    | IRemoveEvent
    | IRemoveByIdEvent
    | IClearEvent
    | IValidateEvent
    | IInitializeEvent
    | IAddOrReplaceCartItemsEvent;

export function reduceCartState(state: CartItem[], action: CartEvents): CartItem[] {
    switch (action.type) {
        case 'ADD': {
            const cartItemId = getCartItemId(action.data);
            // only allow one pkg in cart at a time (for now)
            const existingIdx = state.findIndex((x) => getCartItemId(x) === cartItemId);
            const newState = state.slice();

            if (existingIdx !== -1) newState[existingIdx] = action.data;
            else newState.push(action.data);
            return newState;
        }
        case 'ADD_OR_REPLACE_CART_ITEMS': {
            const newState = state.slice();
            action.data.forEach((item) => {
                const cartItemId = getCartItemId(item);
                const existingIdx = state.findIndex((x) => getCartItemId(x) === cartItemId);
                if (existingIdx !== -1) {
                    newState[existingIdx] = item;
                } else {
                    newState.push(item);
                }
            });

            return newState;
        }
        case 'REMOVE': {
            const newState = state.slice();
            const removedItem = newState[action.data];
            newState.splice(action.data, 1);

            return removeRelatedExtras(newState, removedItem);
        }
        case 'REMOVE_BY_ID': {
            const newState = state.slice();
            const index = newState.findIndex((item) => getCartItemId(item) === action.data);

            if (index !== -1) {
                const removedItem = newState[index];
                newState.splice(index, 1);

                return removeRelatedExtras(newState, removedItem);
            }

            return newState;
        }
        case 'CLEAR':
            return [];
        case 'INITIALIZE':
            return action.data;
        case 'VALIDATE':
            const validItems = removeInvalidCartItems(state);
            const newState = state.length === validItems.length ? state : validItems;
            return newState;
        default:
            return state;
    }
}

export function createAddProductsToCartEvent(
    products: ProductInstance[],
    ticketOptions: TicketOptionWithQuantity[],
    disablePaymentPlans: boolean,
    requiresPaymentPlans: boolean,
    pkg?: { pkg: Package; date: TZDate },
    ticketType?: TicketType,
    idPrefix?: string,
): IAddEvent {
    return {
        type: 'ADD',
        data: {
            products,
            ticketOptions,
            pkg,
            ticketType,
            idPrefix,
            disablePaymentPlans,
            requiresPaymentPlans,
        },
    };
}

export function addOrReplaceCartEvent(items: CartItem[]): IAddOrReplaceCartItemsEvent {
    return {
        type: 'ADD_OR_REPLACE_CART_ITEMS',
        data: items,
    };
}

export function removeFromCartEvent(cartItem: CartItem): IRemoveEvent {
    const index = cartAtom.subject.value.findIndex(
        (item) => getCartItemId(item) === getCartItemId(cartItem),
    );

    return {
        type: 'REMOVE',
        data: index,
    };
}

export function removeByIdEvent(cartItemId: string): IRemoveByIdEvent {
    return { type: 'REMOVE_BY_ID', data: cartItemId };
}

export function clearCartEvent(): IClearEvent {
    return {
        type: 'CLEAR',
    };
}

export function validateEvent(): IValidateEvent {
    return {
        type: 'VALIDATE',
    };
}
