import { useEffect } from 'react';
import Modal from 'src/components/common/modal/Modal';
import { showBasketAtom } from 'src/state/ui/showBasket.atom';
import BasketPopup from 'src/widgets/activities/basket/BasketPopup';
import { useAtom } from 'ximple';
import { cartAtom } from 'src/state/cart/cartAtom';

export default function BasketWidget() {
    const [cart] = useAtom(cartAtom);

    useEffect(() => {
        if (cart.length === 0) {
            showBasketAtom.update({ visible: false });
        }
    }, [cart]);

    const [{ visible, refocusElementOnClose }] = useAtom(showBasketAtom);
    const onClose = () => showBasketAtom.update({ visible: false });

    return (
        <Modal
            open={visible}
            id="basket-modal"
            onClose={onClose}
            refocusElementOnClose={refocusElementOnClose}
        >
            <BasketPopup onCloseModal={onClose} />
        </Modal>
    );
}
