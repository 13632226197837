import { useAtom } from 'ximple';
import { paymentPlanAtom } from 'src/state/payment-plan/paymentPlanAtom';
import { Box, Button, CircularProgress, Grid, useTheme } from '@mui/material';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ButtonDisplay } from 'src/widgets/activities/checkout/cart/views/ButtonDisplay';
import Summary from '../summary/Summary';
import PaymentOptionsPanel from '../timeslots-payment-options-panel/PaymentOptionsPanel';
import { useCartCheckoutContext } from 'src/widgets/activities/checkout/cart/CartCheckoutContext';

type IntentViewProps = {
    handleGoPayClicked: () => void;
};

export default function IntentView(props: IntentViewProps) {
    const { handleGoPayClicked } = props;

    const [{ isLoadingPrices: isLoadingIntents }] = useAtom(paymentPlanAtom);
    const { activeTab, largeScreen, isMakingReservation } = useCartCheckoutContext();

    return (
        <>
            <Grid
                container
                justifyContent={largeScreen ? 'space-between' : 'center'}
                alignItems={largeScreen ? 'flex-start' : 'center'}
                direction={largeScreen ? 'row' : 'column-reverse'}
            >
                {!largeScreen && !isLoadingIntents && (
                    <GoPayButton
                        isMakingReservation={isMakingReservation}
                        handleGoPayClicked={handleGoPayClicked}
                    ></GoPayButton>
                )}

                <Box pt={2}>
                    {!isLoadingIntents && <PaymentOptionsPanel />}
                    {isLoadingIntents && <CircularProgress color="primary" />}
                </Box>

                <Summary
                    showShare
                    hideGoPay={!largeScreen}
                    isCheckoutSummary={true}
                    isMakingReservation={isMakingReservation}
                    goPayClicked={handleGoPayClicked}
                    activeTab={activeTab}
                />
            </Grid>
        </>
    );
}

function GoPayButton(props: { isMakingReservation: boolean; handleGoPayClicked: () => void }) {
    const theme = useTheme();
    const customizations = useCustomizations();

    return (
        <Box
            maxWidth="440px"
            width="100%"
            sx={{
                [theme.breakpoints.down('md')]: {
                    marginBottom: theme.spacing(3),
                },
            }}
        >
            <Button
                sx={{ float: 'right', marginTop: theme.spacing(2) }}
                color="primary"
                variant={customizations.primaryButtonStyle}
                disabled={props.isMakingReservation}
                onClick={props.handleGoPayClicked}
            >
                <ButtonDisplay isMakingReservation={props.isMakingReservation} />
            </Button>
        </Box>
    );
}
