import { Box, Button, darken, Grid, Popover, Slide, useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TZDate } from '@repo/tzdate';
import { createRef, useState, useContext, useMemo } from 'react';
import TimeSlots from 'src/components/domain/time-slot/TimeSlots';
import BookingTimeslots from 'src/components/domain/timeslots-booking/calendar/booking-timeslots/BookingTimeslots';
import { CalendarHeader } from 'src/components/domain/timeslots-booking/calendar/calendar-header/CalendarHeader';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from '@repo/i18n';
import { ProductInstance, TicketOptionWithQuantity, Timeslot } from '@repo/types';
import { MountPointContext } from '@repo/common-utils/mountPoint';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { zIndex } from 'src/utils/widget/zIndex';
import { findAvailabilityData, getAllDatesInDateRange } from '@repo/widget-utils/DateHelpers';
import { TimeSlotType } from '@repo/widget-utils/TimeSlotType';
import StaticDateRangePicker from '../../date-range-picker/StaticDateRangePicker';
import BilberryStaticCalendar from '../BilberryStaticCalendar';

interface IProps {
    dateRangeVariant?: 'days' | 'nights';
    selectedDateRange: DateRange<TZDate> | undefined;
    onSelectDateRange: ((dateRange: DateRange<any>) => void) | undefined;
    minDate: TZDate;
    displayDate: TZDate | null;
    displayTimeslot: TimeSlotType | undefined;
    setDisplayTimeslot: React.Dispatch<React.SetStateAction<TimeSlotType | undefined>>;
    anchorEl: HTMLDivElement | HTMLInputElement | null;
    availabilityData: ProductInstance[];
    updateDisplayDate: (date: TZDate | null) => void;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | HTMLInputElement | null>>;
    onSelectTimeSlot?: (timeslot: TimeSlotType | undefined) => void;
    setSelectedProducts?: (product: ProductInstance[] | undefined) => void;
    setAvailabilitySearchPeriod: (availabilitySearchPeriod: {
        startDay: TZDate | null;
        endDay: TZDate | null;
    }) => void;
    id?: string;
    quantities: TicketOptionWithQuantity[];
    setHasChosenDate: (hasChosenDate: boolean) => void;
    availableTimeslots?: Timeslot[];
}

export default function CalendarPopover(props: IProps) {
    const {
        dateRangeVariant,
        selectedDateRange,
        onSelectDateRange,
        minDate,
        displayDate,
        displayTimeslot,
        setDisplayTimeslot,
        anchorEl,
        availabilityData,
        updateDisplayDate,
        setAnchorEl,
        onSelectTimeSlot,
        setSelectedProducts,
        setAvailabilitySearchPeriod,
        id = 'bilberry-calendar-input',
        quantities,
        setHasChosenDate,
        availableTimeslots,
    } = props;
    const customizations = useCustomizations();
    const mountPoint = useContext(MountPointContext);
    const popoverRef = createRef<HTMLDivElement>();
    const theme = useTheme();
    const { t } = useLocale();
    const threeYearsAhead = minDate.add(5, 'year');
    const trap = useFocusTrap(popoverRef, !!anchorEl, `#${id}`, mountPoint.shadowRoot);
    const [timeslotSelectorVisible, setTimeslotSelectorVisible] = useState<boolean>(false);

    const updateAvailabilitySearchPeriod = (date: TZDate | null) => {
        if (!date) return;

        setAvailabilitySearchPeriod({
            startDay: date.startOf('month').startOf('day'),
            endDay: date.endOf('month').add(1, 'day').startOf('day'),
        });
    };

    const onSelectDay = (date: TZDate | null) => {
        updateDisplayDate(date);
    };

    const onClose = () => {
        if (trap) trap.deactivate(200);
        setAnchorEl(null);
    };

    const availabilityDataForDisplayDate = findAvailabilityData(
        displayDate,
        availabilityData,
        quantities,
    );

    const dateHasTicketAvailability = availabilityDataForDisplayDate.length > 0;

    const disableOkButton =
        (!displayTimeslot && !dateRangeVariant) ||
        (dateRangeVariant === 'nights' && selectedDateRange?.includes(null));

    const isTimeslot =
        displayTimeslot?.product?.product?.type === 'timeslot' ||
        availabilityData[0]?.product?.type === 'timeslot' ||
        !!availableTimeslots;

    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            sx={{
                zIndex: `${zIndex.alwaysVisible} !important` as any,

                '& > .MuiPaper-root': {
                    maxWidth: 325,
                },
            }}
            container={mountPoint.popover}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onClose={onClose}
            aria-modal={true}
            disableEnforceFocus={true}
            ref={popoverRef}
        >
            <DateRangePickerOrCalendar
                dateRangeVariant={dateRangeVariant}
                selectedDateRange={selectedDateRange}
                onSelectDateRange={onSelectDateRange}
                updateAvailabilitySearchPeriod={updateAvailabilitySearchPeriod}
                minDate={minDate}
                threeYearsAhead={threeYearsAhead}
                displayDate={displayDate}
                onSelectDay={onSelectDay}
                availabilityDataForDisplayDate={availabilityDataForDisplayDate}
                displayTimeslot={displayTimeslot}
                setDisplayTimeslot={setDisplayTimeslot}
                availabilityData={availabilityData}
                availableTimeslots={availableTimeslots}
                timeslotSelectorVisible={timeslotSelectorVisible}
                setTimeslotSelectorVisible={setTimeslotSelectorVisible}
                quantities={quantities}
            />

            <Box pt={1} pb={2}>
                <Grid container justifyContent="right" pl={2} pr={2.5} gap={1}>
                    {dateHasTicketAvailability ? (
                        <>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    onSelectTimeSlot?.(undefined);
                                    setSelectedProducts?.(undefined);
                                    setHasChosenDate(false);
                                    onClose();
                                }}
                                role={capitalize(t.select_date)}
                            >
                                {t.clear}
                            </Button>
                            <Button
                                sx={{
                                    '&:hover': {
                                        backgroundColor:
                                            customizations.primaryButtonStyle === 'contained'
                                                ? darken(theme.palette.primary.main, 0.2)
                                                : 'rgba(0, 0, 0, 30%)',
                                    },
                                }}
                                variant={customizations.primaryButtonStyle}
                                color="primary"
                                disabled={disableOkButton}
                                onClick={() =>
                                    isTimeslot && !timeslotSelectorVisible
                                        ? setTimeslotSelectorVisible(true)
                                        : onOkClicked(
                                              dateRangeVariant,
                                              selectedDateRange,
                                              displayTimeslot,
                                              onSelectTimeSlot,
                                              setSelectedProducts,
                                              getAllDatesInDateRange,
                                              availabilityData,
                                              findAvailabilityData,
                                              onClose,
                                              quantities,
                                              setTimeslotSelectorVisible,
                                          )
                                }
                                role={capitalize(t.select_date)}
                            >
                                {t.ok.toUpperCase()}
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setHasChosenDate(true);
                                setSelectedProducts?.([displayTimeslot!.product]);
                                onClose();
                            }}
                        >
                            {t.see_available_tickets}
                        </Button>
                    )}
                </Grid>
            </Box>
        </Popover>
    );
}

function DateRangePickerOrCalendar(props: {
    dateRangeVariant: 'days' | 'nights' | undefined;
    selectedDateRange: DateRange<TZDate> | undefined;
    onSelectDateRange:
        | ((date: DateRange<unknown>, keyboardInputValue?: string | undefined) => void)
        | undefined;
    updateAvailabilitySearchPeriod: (date: TZDate | null) => void;
    minDate: TZDate | undefined;
    threeYearsAhead: TZDate;
    displayDate: TZDate | null;
    onSelectDay: (date: TZDate | null) => void;
    availabilityDataForDisplayDate: ProductInstance[] | undefined;
    displayTimeslot: TimeSlotType | undefined;
    setDisplayTimeslot: React.Dispatch<React.SetStateAction<TimeSlotType | undefined>>;
    availabilityData: ProductInstance[];
    availableTimeslots?: Timeslot[];
    timeslotSelectorVisible: boolean;
    setTimeslotSelectorVisible: React.Dispatch<React.SetStateAction<boolean>>;
    quantities: TicketOptionWithQuantity[];
}) {
    const {
        availabilityData,
        availabilityDataForDisplayDate,
        dateRangeVariant,
        displayDate,
        displayTimeslot,
        minDate,
        onSelectDateRange,
        onSelectDay,
        selectedDateRange,
        setDisplayTimeslot,
        threeYearsAhead,
        updateAvailabilitySearchPeriod,
        timeslotSelectorVisible,
        quantities,
        setTimeslotSelectorVisible,
    } = props;

    const isTimeslot =
        displayTimeslot?.product?.product?.type === 'timeslot' ||
        availabilityData[0]?.product?.type === 'timeslot';

    const availabilityDataDays = useMemo(() => {
        const now = Date.now();
        return availabilityData.reduce((acc, cur) => {
            const cutoff = cur.cutoffDate ?? cur.start;

            if (cur.capacity > 0 && cutoff.isAfter(now)) {
                acc.set(cur.start.format('YYYY-MM-DD'), true);
            }
            return acc;
        }, new Map<string, boolean>());
    }, [availabilityData]);

    if (dateRangeVariant) {
        if (selectedDateRange && onSelectDateRange)
            return (
                <StaticDateRangePicker
                    dateRangeVariant={dateRangeVariant}
                    dateRange={selectedDateRange}
                    onChange={onSelectDateRange}
                    onMonthChange={updateAvailabilitySearchPeriod}
                    minDate={minDate}
                    maxDate={threeYearsAhead}
                    isDateUnavailable={(date) => {
                        return isDateUnavailable(date, availabilityDataDays);
                    }}
                    loading={false}
                />
            );
        else return null;
    }

    return (
        <>
            <CalendarHeader
                onClick={
                    !isTimeslot || !timeslotSelectorVisible
                        ? undefined
                        : () => setTimeslotSelectorVisible(false)
                }
                selectedDate={displayDate}
            />
            {!timeslotSelectorVisible && (
                <>
                    <BilberryStaticCalendar
                        date={displayDate}
                        onChange={onSelectDay}
                        onMonthChange={updateAvailabilitySearchPeriod}
                        onYearChange={updateAvailabilitySearchPeriod}
                        minDate={minDate}
                        maxDate={threeYearsAhead}
                        shouldDisableDate={(date) => isDateUnavailable(date, availabilityDataDays)}
                        loading={false}
                    />

                    {!isTimeslot && (
                        <TimeSlots
                            availabilityData={availabilityDataForDisplayDate}
                            selectedTimeSlot={displayTimeslot ?? undefined}
                            onSelectTimeSlot={setDisplayTimeslot}
                        />
                    )}
                </>
            )}
            {timeslotSelectorVisible && (
                <Slide direction="left" in={timeslotSelectorVisible}>
                    <Box
                        sx={(theme) => ({
                            padding: theme.spacing(2, 0),
                            width: '325px',
                            height: '385px',
                            overflow: 'auto',
                        })}
                    >
                        <BookingTimeslots
                            onSelectTimeslot={setDisplayTimeslot}
                            selectedTimeslot={displayTimeslot}
                            productInstance={displayTimeslot?.product}
                            participants={quantities.reduce((acc, cur) => acc + cur.quantity, 0)}
                        />
                    </Box>
                </Slide>
            )}
        </>
    );
}

function onOkClicked(
    dateRangeVariant: 'days' | 'nights' | undefined,
    selectedDateRange: DateRange<TZDate> | undefined,
    displayTimeslot: TimeSlotType | undefined,
    onSelectTimeSlot: ((timeslot: TimeSlotType) => void) | undefined,
    setSelectedProducts: ((product: any[] | undefined) => void) | undefined,
    getAllDatesInDateRange: (arg0: DateRange<TZDate> | undefined) => any[],
    availabilityData: ProductInstance[],
    findAvailabilityDataNew: (arg0: any, arg1: any, arg2: any) => any[],
    onClose: () => void,
    quantities: TicketOptionWithQuantity[],
    setTimeslotSelectorVisible: React.Dispatch<React.SetStateAction<boolean>>,
) {
    setTimeslotSelectorVisible(false);
    if (!dateRangeVariant) {
        setSelectedProducts?.([displayTimeslot!.product]);
        if (onSelectTimeSlot) {
            onSelectTimeSlot(displayTimeslot!);
        }
    } else {
        // Allow selecting a single day in the date range picker. Represent this by copying the start date into the end date.
        const dateRange =
            dateRangeVariant === 'days' && !!selectedDateRange?.[0] && !selectedDateRange?.[1]
                ? ([selectedDateRange[0], selectedDateRange[0]] as DateRange<TZDate>)
                : selectedDateRange;
        setSelectedProducts?.(
            getAllDatesInDateRange(dateRange)
                .map((date: any) => findAvailabilityDataNew(date, availabilityData, quantities)[0])
                // Handle the case where the end date is not available by copying the product (since its' otherwise undefined), and modifying start and end.
                .map(
                    (x, i, arr) =>
                        x ?? {
                            ...arr[i - 1],
                            start: arr[i - 1].start.add(1, 'day'),
                            end: arr[i - 1].end.add(1, 'day'),
                        },
                ),
        );
    }
    onClose();
}

function isDateUnavailable(
    date: string | null,
    availabilityDataDays: Map<string, boolean>,
): boolean {
    if (!date) return false;
    return !availabilityDataDays.has(date);
}
