import { useEffect } from 'react';
import { useAtom } from 'ximple';
import { useAtomSelector } from 'src/hooks/useSelector';
import {
    paymentPlanAtom,
    setIsLoadingPrices,
    setIsUsingPaymentPlan,
    setPaymentPlanResponse,
    reset,
} from './paymentPlanAtom';
import { voucherAtom } from '../voucher/voucherAtom';
import { cartAtom } from '../cart/cartAtom';
import { checkoutInfoAtom, selectIsCompanyContact } from '../checkout-info/checkoutInfoAtom';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useIntentMulti } from '@repo/widget-utils/services/hooks/membership';
import useIntentMultiRequest from 'src/hooks/domain/cart/useIntentMultiRequest';

export function usePaymentPlanEffects() {
    const [{ appliedPromoCode, ignoredValueCards }] = useAtom(voucherAtom);
    const { loggedInUser, currentSite } = useMemberContext();
    const [cart] = useAtom(cartAtom);
    const isCompanyContact = useAtomSelector(checkoutInfoAtom, selectIsCompanyContact);

    const {
        request: currentPaymentPlanRequest,
        isUsingPaymentPlan,
        isRequestChanging,
    } = useIntentMultiRequest(isCompanyContact, cart, appliedPromoCode, ignoredValueCards);

    const { data: paymentPlanResponse, isLoading: isLoadingPaymentPlan } = useIntentMulti(
        currentPaymentPlanRequest,
        loggedInUser,
        currentSite?.key,
    );

    useEffect(() => {
        // Reset paymentPlan if cart is empty.
        if (!cart.length && (isUsingPaymentPlan || paymentPlanResponse))
            paymentPlanAtom.update(reset());
    }, [cart, isUsingPaymentPlan, paymentPlanResponse]);

    useEffect(() => {
        paymentPlanAtom.update(
            setIsLoadingPrices(isUsingPaymentPlan && (isLoadingPaymentPlan || isRequestChanging)),
        );
    }, [isUsingPaymentPlan, isLoadingPaymentPlan, isRequestChanging]);

    useEffect(() => {
        paymentPlanAtom.update(setIsUsingPaymentPlan(isUsingPaymentPlan));
    }, [isUsingPaymentPlan]);

    useEffect(() => {
        paymentPlanAtom.update(setPaymentPlanResponse(paymentPlanResponse || null));
    }, [paymentPlanResponse]);
}
