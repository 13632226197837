import { BilberryGiftcardStatus } from '@repo/types';
import { tzdate } from '@repo/tzdate';

export enum GiftcardValidationResult {
    ZERO_BALANCE = -1,
    EXPIRED = 0,
    VALID = 1,
}

export function isGiftcardStatusValid(
    giftcardStatus: BilberryGiftcardStatus,
): GiftcardValidationResult {
    // If balance is negative or 0
    if (giftcardStatus.balance <= 0) return GiftcardValidationResult.ZERO_BALANCE;

    // If giftcard has expired
    if (tzdate(giftcardStatus.expires_at, false).isBefore(tzdate()))
        return GiftcardValidationResult.EXPIRED;

    return GiftcardValidationResult.VALID;
}
