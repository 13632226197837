import { createRef, useEffect } from 'react';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import {
    initializeHiddenBodyStyle,
    useHiddenBody as useHiddenBody,
} from 'src/hooks/common/ui/useHiddenBody';
import { useWidgetEventEffect } from 'src/hooks/domain/events/useWidgetEventEffect';
import { useCartCheckoutContext } from './CartCheckoutContext';
import { CartCheckoutTabs } from './CartCheckoutTabs';
import { voucherAtom, resetIgnoredValueCards } from 'src/state/voucher/voucherAtom';
import { cartAtom } from 'src/state/cart/cartAtom';
import { validateEvent } from 'src/state/cart/cart.reducer';

const hiddenBodyStyle = initializeHiddenBodyStyle('activities');

export function Cart(): JSX.Element {
    const sentinelRef = createRef<HTMLDivElement>();

    const [cartItems, cartDispatch] = useAtomReducer(cartAtom);
    const [{ appliedPromoCode }, voucherDispatch] = useAtomReducer(voucherAtom);

    useEffect(() => {
        // Reset ignored value cards when the checkout process starts
        voucherDispatch(resetIgnoredValueCards());
        cartDispatch(validateEvent()); // Makes sure everything in cart is valid and not past cutoff date
    }, [cartDispatch, voucherDispatch]);

    const { paymentRedirectContext, appliedGiftcards } = useCartCheckoutContext();

    useEffect(() => {
        sentinelRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useHiddenBody(hiddenBodyStyle);

    // Dispatch book event if redirected from Vipps)
    useWidgetEventEffect(
        (cartItems, giftCards, promoCode) => ({
            eventType: 'book',
            cartItems: Object.values(cartItems),
            giftCards: giftCards
                ? giftCards.map((giftCard) => ({
                      priceReduction: giftCard.priceReduction,
                      giftcardReference: giftCard.giftcardStatus.id,
                  }))
                : undefined,
            promoCode: promoCode,
        }),
        cartItems,
        appliedGiftcards,
        appliedPromoCode,
        () => !paymentRedirectContext,
    );

    return <CartCheckoutTabs></CartCheckoutTabs>;
}
