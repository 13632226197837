import { ReactNode, useEffect, useId } from 'react';
import { atom, useAtom } from 'ximple';
import { useCheckoutInfoEffects } from './checkout-info/checkoutInfo.effects';
import { usePaymentPlanEffects } from './payment-plan/paymentPlan.effects';
import { useCartMembershipEffects } from './cart/cart.effects';
import {
    useResetVoucherEffects,
    useValidatePromoCodeEffects,
    useValidateGiftcardsEffects,
} from './voucher/voucher.effects';

const isActiveAtom = atom<null | string>({
    initialValue: null,
    update: (state, value) => {
        return !state || !value ? value : state;
    },
});

// This provider is only meant as a container for hooks that trigger state side-effects.
//
// Since this provider is added multiple times (once for each web-component), we have
// some logic (active/setActive) to prevent effects from being mounted more than once.
export function StateProvider({ children }: { children: ReactNode }) {
    const id = useId();
    const [active, setActive] = useAtom(isActiveAtom);

    useEffect(() => {
        // Attempt to grab lock by setting this component's id, only succeeds if active
        // is currently set to null. For maximum atomicity, the atom-update-function
        // will decide if it is successfull.
        setActive(id);

        // If the parent web-component somehow unmounts, in turn unmounting this
        // instance, we release lock in order to allow a different instance of
        // state-provider to grab lock.
        return () => {
            if (active === id) setActive(null);
        };
    }, [id, active, setActive]);

    return (
        <>
            {children}
            {active === id && <StateEffects />}
        </>
    );
}

// Side-effect-hooks should be added here.
function StateEffects() {
    useCheckoutInfoEffects();
    usePaymentPlanEffects();
    useCartMembershipEffects();
    useResetVoucherEffects();
    useValidatePromoCodeEffects();
    useValidateGiftcardsEffects();

    return null;
}
