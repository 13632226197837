import { useMemo } from 'react';
import { useAtom } from 'ximple';
import { CreateValueCardUsageSummary } from '@repo/widget-utils/price-helper';
import { paymentPlanAtom } from 'src/state/payment-plan/paymentPlanAtom';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function useValueCardUsageSummary() {
    const { valueCardsForUser } = useMemberContext();
    const [{ paymentPlanResponse }] = useAtom(paymentPlanAtom);

    const valueCardUsageSummary = useMemo(() => {
        if (!paymentPlanResponse) return undefined;

        return CreateValueCardUsageSummary(paymentPlanResponse, valueCardsForUser);
    }, [paymentPlanResponse, valueCardsForUser]);

    return valueCardUsageSummary;
}
