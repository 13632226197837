/* eslint-disable max-len */

import { RawTranslations } from '@repo/types';

const translations: RawTranslations = {
    show_cancellation_policy: 'Mostrar política de cancelación',
    hide_cancellation_policy: 'Ocultar política de cancelación',
    departure: 'Salida',
    price: 'Precio',
    difficulty: 'Dificultad',
    duration: 'Duración',
    starting_time: 'Hora de inicio',
    about: 'Acerca de',
    book_now: 'Reservar ahora',
    book: 'Reservar',
    buy_as_gift: 'Comprar como regalo',
    from: 'Desde',
    your_booking_reference_is: 'Tu referencia de reserva es',
    booking_details_in_email: 'Pronto recibirás un correo electrónico con más información',
    see_more_activities: 'Ver más actividades',
    your_cart: 'Tu carrito',
    in_your_cart: 'En tu carrito',
    when_are_you_going: 'Elige una fecha y hora',
    how_many_are_going: '¿Cuántos van?',
    contact_details: 'Detalles de contacto',
    traveler: 'Viajero',
    extras: 'Extras',
    please_select: 'Por favor, selecciona',
    no_items_in_cart: 'No hay artículos en tu carrito de compras',
    payment: 'Pago',
    receipt: 'Recibo',
    do_you_have_any_comments_or_special_needs: '¿Tienes algún comentario o necesidades especiales?',
    i_have_read_and_accepted_the: 'He leído y aceptado la',
    privacy_policy: 'política de privacidad',
    terms_and_conditions: 'términos y condiciones',
    first_name: 'Nombre',
    last_name: 'Apellido',
    phone_number: 'Número de teléfono',
    dial_code: 'Código de marcación',
    email: 'Correo electrónico',
    please_register: 'Por favor, regístrate',
    main_contact: 'Contacto principal',
    additional_information: 'Información adicional',
    remove_cart_item: 'Eliminar artículo del carrito',
    back_to_webshop: 'Continuar comprando',
    excl: 'excl.',
    vat: 'IVA',
    total: 'Total',
    payNow: 'Paga ahora',
    payLater: 'Paga más tarde',
    only_deposit_amount_to_pay_now: {
        template: 'Solo <NAME> a pagar como depósito ahora.',
    } as any,
    next: 'Siguiente',
    go_and_pay: 'Ir y pagar',
    print: 'Imprimir',
    close: 'Cerrar',
    checkout: 'Pagar',
    summary: 'Resumen',
    cancel: 'Cancelar',
    save: 'Guardar',
    select_time: 'Seleccionar hora',
    selected_time: 'Hora seleccionada',
    time: 'Hora',
    go_back: 'Volver',
    thank_you_for_booking_with_us: '¡Gracias por reservar con nosotros!',
    please_fill_in_all_required_information: 'Por favor, completa toda la información requerida',
    error_occurred_when_creating_reservation: 'Ocurrió un error al crear la reserva',
    error_occurred_when_creating_giftcard: 'Ocurrió un error al crear la tarjeta de regalo',
    couldnt_apply_promocode_is_the_code_correct:
        'No se pudo aplicar el código de promoción. ¿Es el código correcto?',
    choose_a_country: 'Elige un país',
    select_date: 'Seleccionar fecha',
    select_travelers: 'Seleccionar viajeros',
    hourShort: 'h',
    dayShort: 'd',
    minuteShort: 'm',
    years: 'años',
    age: 'Edad',
    more_information: 'Más información',
    same_as_main_contact: 'Igual que el contacto principal',
    easy: 'Fácil',
    medium: 'Medio',
    hard: 'Difícil',
    please_select_date: 'Por favor, selecciona una fecha',
    please_select_date_and_time: 'Por favor, selecciona fecha y hora',
    name: 'Nombre',
    comment: 'Comentario',
    submit_request: 'Enviar solicitud',
    any_special_requests_etc: '¿Alguna solicitud especial, alergias alimentarias...?',
    request_tour_comment: 'Describe tu solicitud',
    thank_you_for_your_request: 'Gracias por tu solicitud, te responderemos rápidamente.',
    got_it: '¡Entendido!',
    continue_shopping: 'Continuar comprando',
    continue_travelers: 'Viajeros',
    please_provide_your_name: 'Por favor, proporciona un nombre',
    please_provide_your_email: 'Por favor, proporciona un correo electrónico válido',
    please_provide_a_description: 'Por favor, proporciona una descripción',
    an_error_occurred_when_submitting_your_request:
        'Ocurrió un error al enviar tu solicitud. Por favor',
    private_tour: 'Tour privado',
    read_more_and_book: 'Leer más y reservar',
    read_more: 'Leer más',
    prices_from: 'Precios desde',
    about_the_trip: 'Acerca del viaje',
    prices: 'Precios',
    whats_included: '¿Qué está incluido?',
    any: 'Cualquier',
    duration_up_to_1_hour: 'Hasta 1 hora',
    duration_1_2_hours: '1 - 2 horas',
    duration_2_6_hours: '2 - 6 horas',
    duration_6_12_hours: '6 - 12 horas',
    duration_12_24_hours: '12 - 24 horas',
    duration_1_2_days: '1 - 2 días',
    duration_more_than_2_days: 'Más de 2 días',
    find_activities: 'Buscar actividades',
    ok: 'Aceptar',
    clear: 'Limpiar',
    filterActivities: 'Filtrar actividades',
    hideFilters: 'Ocultar filtros',
    shopMore: 'Comprar más',
    noSearchResults:
        'Lo siento, no encontramos actividades disponibles que coincidan con tu búsqueda.',
    update: 'Actualizar',
    select_date_to_view_timeslots: 'Selecciona una fecha para ver los horarios disponibles',
    your_reservation_timed_out: 'Tu reserva ha expirado. Por favor, inténtalo de nuevo.',
    aria_calendar_input_field_label: 'Campo de entrada de calendario. Presiona Enter para abrir.',
    aria_book_now_button: 'Abrir una ventana para reservar esta actividad',
    aria_next_image: 'Siguiente imagen',
    aria_previous_image: 'Imagen anterior',
    aria_open_booking_widget: 'Abrir ventana de reserva',
    aria_close_booking_widget: 'Cerrar ventana de reserva',
    aria_open_request_tour_widget: 'Abrir ventana de solicitud de tour',
    aria_close_request_tour_widget: 'Cerrar ventana de solicitud de tour',
    aria_open_find_activity: 'Abrir buscar actividad',
    aria_close_find_activity: 'Cerrar buscar actividad',
    aria_show_giftcard_input: 'Mostrar campo de tarjeta de regalo',
    aria_hide_giftcard_input: 'Ocultar campo de tarjeta de regalo',
    fill_giftcard_code: 'Ingresar código de tarjeta de regalo',
    aria_show_promocode_input: 'Mostrar campo de código promocional',
    aria_hide_promocode_input: 'Ocultar campo de código promocional',
    aria_price_to_pay_summary: 'Resumen del precio a pagar',
    fill_promocode: 'Ingresar código promocional',
    request_tour: 'Solicitar tour',
    giftcard_message: 'mensaje',
    giftcard_message_label: 'mensaje personal al destinatario (opcional)',
    recipient_details: 'detalles del destinatario',
    gift_card: 'tarjeta de regalo',
    value: 'valor',
    create: 'crear',
    create_your_gift: 'crear tu tarjeta de regalo',
    your_giftcard_reference_is: 'tu referencia es',
    giftcard_voucher_and_receipt:
        'te hemos enviado un vale de regalo listo para imprimir junto con un recibo a:',
    giftcard_voucher: 'te hemos enviado el vale de regalo a:',
    giftcard_and_receipt: 'y un recibo a ti en:',
    thank_you_for_your_purchase: '¡Gracias por tu compra!',
    valid_until: 'válido hasta',
    print_receipt: 'imprimir recibo',
    select_gift_type: 'seleccionar tipo de regalo',
    please_select_gift_type: 'por favor, selecciona el tipo de regalo',
    select_gift_print_description:
        'recibe una tarjeta de regalo imprimible en tu bandeja de entrada',
    select_gift_email_description:
        'envía una tarjeta de regalo por correo electrónico directamente al destinatario',
    enter_giftcard_code: 'Ingresa un código de tarjeta de regalo',
    enter_promo_code: 'Ingresa un código promocional',
    apply: 'aplicar',
    giftcard_code_error: 'código de tarjeta de regalo no válido',
    remaining_value: 'valor restante',
    giftcard_code_not_found: 'no se pudo encontrar la tarjeta de regalo con el código:',
    giftcard_zero_balance: 'la tarjeta de regalo ya está gastada',
    giftcard_is_expired: 'la tarjeta de regalo ha caducado',
    purchased_giftcard: {
        self: 'tarjeta de regalo imprimible enviada a ti',
        recipient: 'tarjeta de regalo imprimible enviada al destinatario',
    },
    contact_information: {
        short: 'info de contacto',
        long: 'información de contacto',
    },
    minutes: {
        singular: 'min',
        plural: 'min',
        long: 'minuto',
    },
    hours: {
        singular: 'hora',
        plural: 'horas',
    },
    days: {
        singular: 'día',
        plural: 'días',
    },
    children: {
        singular: 'niño',
        plural: 'niños',
    },
    adult: {
        singular: 'adulto',
        plural: 'adultos',
    },
    card: {
        singular: 'tarjeta',
        plural: 'tarjetas',
    },
    number_of_travelers_exceeded: {
        template: 'El tamaño máximo del grupo este día es <COUNT:persona:personas>',
    },
    booking_is_closed: {
        template: 'La reserva para <NAME> está cerrada ahora',
    },
    rooms: {
        singular: 'habitación',
        plural: 'habitaciones',
    },
    person: {
        singular: 'persona',
        plural: 'personas',
    },
    select_room: 'seleccionar habitación',
    change_room: 'cambiar habitación',
    less_than: 'menos de',
    year: {
        singular: 'año',
        plural: 'años',
    },
    select_age: 'seleccionar edad',
    done: 'listo',
    number_of_rooms: 'número de habitaciones',
    select_bed: 'seleccionar cama',
    room_info: 'información de la habitación',
    price_one_night_one_guest: {
        template: 'precio por <COUNT:noche:noches>, <COUNT:huésped:huéspedes>',
    },
    one_night_one_guest: {
        template: '<COUNT:noche:noches>, <COUNT:huésped:huéspedes>',
    },
    price_nights: {
        template: 'precio por <COUNT:noche:noches>',
    },
    night: {
        singular: 'noche',
        plural: 'noches',
    },
    select_room_for_total_price: 'selecciona habitaciones para obtener el precio total',
    find_rooms: 'buscar habitaciones',
    check_in_check_out: 'Registro de entrada y salida',
    guest_and_rooms: 'Huéspedes y habitaciones',
    no_accommodations_found:
        'Lo sentimos, no pudimos encontrar habitaciones que coincidan con tus criterios de búsqueda. ¿Has considerado ajustar tus parámetros de búsqueda para encontrar habitaciones disponibles?',
    facilities_included: 'Facilidades incluidas',
    price_per_night: 'Precio por noche',
    season: 'Temporada',
    has_airconditioning: 'Aire acondicionado',
    has_balcony: 'Balcón',
    has_bicycles: 'Bicicletas',
    has_boat: 'Barco',
    has_coffee_maker: 'Cafetera',
    has_dishwasher: 'Lavavajillas',
    has_dryer: 'Secadora',
    has_fireplace: 'Chimenea',
    has_garage: 'Garaje',
    has_garden_furniture: 'Muebles de jardín',
    has_grill: 'Parrilla',
    has_kitchen: 'Cocina',
    has_minibar: 'Minibar',
    has_mooring_space: 'Espacio de amarre',
    has_pool: 'Piscina',
    has_roomservice: 'Servicio de habitaciones',
    has_sauna: 'Sauna',
    has_tv: 'Televisión',
    has_washing_machine: 'Lavadora',
    has_wifi: 'WiFi',
    is_breakfast_included: 'Desayuno incluido',
    is_cancellation_included: 'Posibilidad de cancelación',
    is_pets_allowed: 'Se admiten mascotas',
    is_smoking_allowed: 'Permitido fumar',
    is_towels_and_bedlines_included: 'Toallas y sábanas',
    is_wheelchair_accessible: 'Accesible en silla de ruedas',
    wifi: 'WiFi',
    breakfast: 'Desayuno',
    accessible: 'Accesible',
    pets: 'Mascotas',
    kitchen: 'Cocina',
    smoking: 'Permitido fumar',
    unknown: 'Desconocido',
    accommodation_cancellation_policy: 'Política de cancelación del alojamiento',
    accommodations: 'Alojamientos',
    activities: 'Actividades',
    address: 'Dirección',
    city: 'Ciudad',
    post_code: 'Código postal',
    to: 'a',
    date_range_start: 'Inicio',
    date_range_end: 'Fin',
    flag_of: 'bandera de',
    monday: 'lunes',
    tuesday: 'martes',
    wednesday: 'miércoles',
    thursday: 'jueves',
    friday: 'viernes',
    saturday: 'sábado',
    sunday: 'domingo',
    today: 'hoy',
    tomorrow: 'mañana',
    january: 'enero',
    february: 'febrero',
    march: 'marzo',
    april: 'abril',
    may: 'mayo',
    june: 'junio',
    july: 'julio',
    august: 'agosto',
    september: 'septiembre',
    october: 'octubre',
    november: 'noviembre',
    december: 'diciembre',
    few_left: 'quedan pocos',
    tickets_left: {
        template: '<PLURALIZE:Queda:Quedan> <COUNT:entrada:entradas>',
    } as any,
    available: 'disponible',
    sold_out: 'agotado',
    upcoming_tours_title: 'Tours próximos',
    unavailable: 'No disponible',
    notes_regarding_your_stay: 'Notas sobre tu estancia',
    notes_for_accommodation_name_with_guests: {
        template: 'Notas para <NAME> con <COUNT:huésped:huéspedes>',
    },
    select_price_type: 'Seleccionar tipo de precio',
    finding_available_rooms: 'Buscando habitaciones disponibles',
    total_price: 'Precio total',
    of: 'de',
    select_dates: 'Seleccionar fechas',
    please_select_price_type: 'Por favor, selecciona el tipo de precio',
    a_minimum_of_x_participants_is_required_to_book_this_product: {
        template: 'Se requiere un mínimo de <COUNT> participantes para reservar este producto',
    } as any,
    aBookingConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Se enviará una confirmación de reserva a tu dirección de correo electrónico tan pronto como hayamos procesado tu pedido.',
    aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder:
        'Se enviará una confirmación a tu dirección de correo electrónico tan pronto como hayamos procesado tu pedido.',
    add: 'Agregar',
    amount: 'Cantidad',
    areYouAMemberOrHaveValueCard: '¿Eres miembro o tienes una tarjeta de valor?',
    areYouSureYouWantToCancelYourBooking: '¿Estás seguro de que deseas cancelar esta reserva?',
    areYouSureYouWantToCancelYourMembership: '¿Estás seguro de que deseas cancelar tu membresía?',
    automaticallyRenewed: 'Renovación automática',
    at: 'En',
    backToWebsite: 'Volver al sitio web',
    belayCertification: 'Certificación de aseguramiento',
    belayCertificationConsent: 'Tengo una certificación de aseguramiento',
    buyMembership: 'Comprar membresía',
    buyNow: 'Comprar ahora',
    cancelled: 'Cancelado',
    canBeCancelledFrom: 'Puede ser cancelado desde',
    choose_payment_method: 'Elegir método de pago',
    bookNow: 'Comprar ahora',
    bookTimeslot: 'Comprar entradas',
    cancellation: 'Cancelación',
    choose: 'Elegir',
    chooseACountry: 'Seleccionar un país',
    chooseCreditsType: 'Seleccionar tipo de créditos',
    chooseDateAndTime: 'Seleccionar fecha y hora',
    chooseNumberOfCredits: 'Seleccionar número de créditos',
    chooseStartDate: 'Seleccionar fecha de inicio',
    chooseType: 'Seleccionar tipo',
    ticketCode: 'Código de orden',
    completed: 'Completado',
    consents: 'Consentimientos',
    contact: 'Contacto',
    contactByEmail: 'Contacto por correo electrónico',
    contactBySms: 'Contacto por SMS',
    couldntApplyGiftcardIsTheCodeCorrect:
        'No se pudo aplicar la tarjeta regalo. ¿Es el código correcto?',
    couldntApplyPromocodeIsTheCodeCorrect:
        'No se pudo aplicar el código promocional. ¿Es el código correcto?',
    couldntGetCompanyInformation: 'No se pudo obtener la información de la empresa',
    couldntGetPaymentPlan:
        'No se pudo obtener el plan de pago. Por favor, intenta actualizar la página web.',
    couldntGetPaymentPlanUsingPreviousPlan:
        'No se pudo obtener el plan de pago, utilizando el plan anterior',
    couldntGetValueCards: 'No se pudieron obtener las tarjetas de valor',
    couldntGetProductPleaseTryAgain: 'No se pudo obtener el producto, por favor inténtalo de nuevo',
    couldntOpenBookingCheckoutNotAllBookingInformationHasBeenSet:
        'No se pudo abrir la confirmación de reserva, no se ha especificado toda la información de reserva requerida.',
    couldntOpenProfilePleaseSignInFirst:
        'No se pudo abrir el perfil, por favor inicia sesión primero.',
    couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet:
        'No se pudo abrir la confirmación de tarjeta de valor, no se ha especificado toda la información de la tarjeta de valor requerida.',
    country: 'País',
    createAccount: 'Crear cuenta',
    credit: {
        singular: 'Crédito',
        plural: 'Créditos',
    },
    credits: 'Créditos',
    creditsLeft: 'Créditos restantes',
    creditDiscount: 'Descuento de crédito',
    discount: 'Descuento',
    free: 'Gratis',
    date: 'Fecha',
    delete: 'Eliminar',
    doYouHaveAnAccount: '¿Tienes una cuenta?',
    doYouHaveValueCards: '¿Tienes una cuenta o alguna tarjeta de valor?',
    doYouHaveBrattkort: '¿Tienes Brattkort o certificación de aseguramiento?',
    doYouHaveClimbingCertification: '¿Tienes una certificación de escalada?',
    edit: 'Editar',
    editTimeslot: 'Editar horario',
    failed: 'Fallido',
    firstName: 'Nombre',
    giftcard: 'Tarjeta de regalo',
    goToMyPage: 'Ir a mi página',
    ifYouWantToDeleteYourProfile: 'Si deseas eliminar tu perfil',
    youCanPurchaseBookingsFromTheShopOnThisWebsite:
        'Puedes comprar entradas desde la tienda en este sitio web.',
    yourOrderReferenceIs: 'Tu referencia de pedido es',
    iHaveReadAndAccepted: 'He leído y aceptado la',
    lastName: 'Apellido',
    postalLocation: 'Ubicación',
    location: 'Ubicación',
    loggedInAs: 'Sesión iniciada como',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    membershipCard: 'Membresía',
    membershipNumber: 'Número de membresía',
    membershipSlashCredits: 'Membresía / Créditos',
    monthlyCharge: 'Cargo mensual',
    myAddress: 'Mi dirección',
    myPage: 'Mi página',
    myProfile: 'Mi perfil',
    nextCreateAccount: 'Siguiente: Crear cuenta',
    no: 'No',
    noneSelected: 'Ninguno seleccionado',
    noSelectedDate: 'Fecha no seleccionada',
    notAllFieldsHaveBeenFilledPleaseFillAllFields:
        'No se han completado todos los campos, por favor proporciona todos los campos.',
    noUserInfoFound: 'No se encontró información de usuario.',
    numberOfPersons: {
        singular: 'persona',
        plural: 'personas',
        template: '<COUNT:persona:personas>',
    },
    pay: 'Pagar',
    payWithCreditCard: 'Pagar con tarjeta de crédito',
    phoneNumber: 'Número de teléfono',
    pleaseChoose: 'Por favor, elige',
    pleaseContact: 'Por favor, contacta',
    pleaseFillOutUserInfoInProfile: 'Por favor, completa la información de usuario en tu perfil.',
    pleaseLogInToBook: 'Por favor, inicia sesión para comprar con estos',
    pleaseLogInToPurchase:
        'Por favor, inicia sesión y completaremos tu información de contacto por ti',
    pleaseProvideAllValueCardInformation:
        'Por favor, proporciona toda la información de la tarjeta de valor',
    pleaseProvideProperBookingInformation: 'Por favor, proporciona información válida',
    pleaseRegister: 'Por favor, regístrate',
    postalCodeAndLocation: 'Código postal / ubicación',
    prefix: 'Prefijo',
    privacyPolicy: 'Política de privacidad',
    problemLoadingData: 'Error al cargar los datos',
    processing: 'Procesando',
    purchaseValueCard: 'Comprar tarjeta de valor',
    readMoreAbout: 'Leer más sobre nuestro',
    readMoreAndBook: 'Leer más y comprar',
    readMoreAndBuy: 'Leer más y comprar',
    refillCredits: 'Recargar créditos',
    returnToWebsite: 'Volver al sitio web',
    scanTicket: 'Escanear boleto',
    signUp: 'Registrarse',
    subtract: 'Restar',
    shoesAndHarnessAlwaysIncluded: 'Zapatos y arnés siempre incluidos.',
    shoesAndHarnessNotIncluded: 'Zapatos y arnés no incluidos.',
    somethingWentWrongUpdatingUserInfo: 'Algo salió mal al actualizar la información de usuario.',
    termsOfUse: {
        template: 'Términos de uso para <NAME>',
    },
    upcomingBookings: 'Reservas futuras',
    updateAddress: 'Actualizar dirección',
    terminate: 'Terminar',
    thankYouForBookingWithUs: 'Gracias por reservar con nosotros.',
    thePrivacyPolicy: 'Política de privacidad',
    thankYouForShoppingWithUs: 'Gracias por comprar con nosotros.',
    thisGiftcardIsEmpty: 'Esta tarjeta de regalo está vacía.',
    tHours: 'horas',
    totalCredits: 'Créditos totales',
    totalNOK: 'Total NOK',
    updateBooking: 'Actualizar reserva',
    updateProfile: 'Actualizar perfil',
    useGiftcard: 'Usar tarjeta de regalo',
    useGiftcardOrPromocode: 'Usar tarjeta de regalo / código de promoción',
    validationNotANumber: 'Debe ser un número',
    validationMaxNumber: {
        template: 'No se permiten números por encima de <NUM>',
    } as any,
    validationMinNumber: {
        template: 'No se permiten números por debajo de <NUM>',
    } as any,
    validForThePeriod: 'Válido por el período',
    validUntil: 'Válido hasta',
    validFrom: 'Válido desde',
    valueCard: 'Tarjeta de valor',
    visitors: 'Visitantes',
    withoutVat: 'Sin IVA',
    yes: 'Sí',
    youDontHaveAnyUpcomingBookings: 'No tienes reservas futuras.',
    youDontHaveAnyValueCards: 'No tienes ninguna tarjeta de valor.',
    youDontHaveAValidMembershipDoYouWantToPurchaseIt:
        'No tienes una membresía válida, ¿deseas comprar una?',
    youMustSpecifyAValueCard: 'Debes especificar una tarjeta de valor válida.',
    yourReservationTimedOut: 'Tu reserva se ha agotado.',
    zipcode: 'Código postal',
    payByMembershipCard: 'Pagar con tarjeta de membresía',
    payWith: 'Pagar con',
    deleteMyProfile: 'Eliminar mi perfil',
    areYouSureYouWantToDeleteYourProfile: '¿Estás seguro de que quieres eliminar tu perfil?',
    deleteYourProfileInformation:
        'Estás a punto de eliminar y desconectar la información de tu perfil de cualquier reserva y producto comprado. Esta acción no se puede deshacer. Es posible que se almacene alguna información por razones legales.',
    choosePaymentOtherMethods: 'Elegir otros métodos de pago',
    dontUseMembershipCardForThisBooking: 'No usar la tarjeta de membresía para esta reserva',
    nameNotProvided: 'Nombre no proporcionado',
    phoneNumberNotProvided: 'Número de teléfono no proporcionado',
    emailNotProvided: 'Correo electrónico no proporcionado',
    addressNotProvided: 'Dirección no proporcionada',
    promocode: 'Código de promoción',
    show_usage_terms: 'Mostrar Términos de Uso',
    hide_usage_terms: 'Ocultar Términos de Uso',
    about_the_product: 'sobre el producto',
    successful: 'Exitoso',
    selectProduct: 'Seleccionar producto',
    withCreditCard: 'Con tarjeta de crédito',
    paymentMethod: 'Método de pago',
    noAvailableTimeslots: 'No hay horarios disponibles',
    orderReference: 'Referencia del pedido',
    iWishToRecieveNewsletters: 'Deseo recibir boletines informativos',
    mySite: 'Mi sitio',
    pleaseConfirm: {
        template: 'Por favor, confirma "<NAME>"',
    } as any,
    pleaseProvideNumberOfParticipants: 'Por favor, proporciona el número de participantes',
    pleaseSelectTheDateAndTimeOfYourBooking: 'Por favor, selecciona la fecha y hora de tu reserva',
    pleaseSelectNumberOfCredits: 'Por favor, selecciona el número de créditos',
    pleaseSelectTypeOfCreditcard: 'Por favor, selecciona el tipo de tarjeta de crédito',
    pleaseSelectAStartDate: 'Por favor, selecciona una fecha de inicio',
    pleaseSelectAMembershipType: 'Por favor, selecciona un tipo de membresía',
    contactCustomerServiceToDeleteYourProfile:
        'Contacta con el servicio de atención al cliente para eliminar tu perfil',
    inclInMembership: 'Incluido en la membresía',
    maxAmount: 'Monto máximo',
    checkIn: 'Entrada',
    checkOut: 'Salida',
    anErrorOccurredDuringPayment: 'Se produjo un error durante el pago',
    anErrorOccurredPleaseTryAgainByReloadingThePageErrorMessage:
        'Se produjo un error, por favor inténtalo nuevamente recargando la página. Mensaje de error',
    share: 'Compartir',
    copyLink: 'Copiar enlace',
    howDoYouWantToShare: '¿Cómo deseas compartirlo?',

    shoppingCart: 'Shopping cart',
    heresMyShoppingCart: "Here's my shopping cart",
    thereIsNotEnoughCapacityForTheItemsInThisCart:
        'There is not enough capacity for the items in this cart',

    full: 'Lleno',
    no_available_capacity_for_this_tour: 'No hay capacidad disponible para este tour',
    one_or_more_activities_or_accommodations_dont_have_enough_capacity:
        'Una o más actividades no tienen suficiente capacidad',
    pick_a_date: 'Selecciona una fecha',
    package: 'Paquete',
    packages: 'Paquetes',
    customize_activities: 'Personaliza actividades',
    check_in_at: 'Check-in a las',
    activity_starts_at: 'La actividad comienza a las',
    other_times_are_available: 'Hay otras horas disponibles',
    press_to_select_other_options: 'Presiona para seleccionar otras opciones',
    unavailableForSelectedTime: 'No disponible en el horario seleccionado',
    order_status_awaiting_payment: 'Procesando pago',
    order_status_payment_failed: 'Pago fallido',
    awaiting_payment_info: 'Procesando el pago... generalmente no tarda más que unos minutos.',
    cancel_membership: 'Cancelar membresía',
    update_subscription_payment_details: 'Actualizar detalles de pago',
    payment_failed_info:
        'Parece que hay un problema con tu pago. Puedes intentar actualizar los detalles de pago.',
    retry_subscription_payment: 'Intentar el pago de nuevo ahora',
    company: 'Empresa',
    company_name: 'Nombre de la empresa',
    org_number: 'Número de organización',
    pay_with_invoice: 'Pagar con factura',
    continueWithRebooking: 'Continuar con la nueva reserva',
    searchAndBookAccommodations: 'Buscar y reservar alojamiento',
    selectNumberOfGuests: 'Seleccionar número de huéspedes',
    checkAvailability: 'Consultar disponibilidad',
    updateAvailability: 'Actualizar disponibilidad',
    you_will_have_to_switch_rooms_during_your_stay:
        'Deberá cambiar de habitación durante su estancia. Póngase en contacto con nosotros para proceder a la reserva.',
    selected: 'Seleccionado',
    remove: 'Eliminar',
    aria_increase: 'Aumento',
    aria_decrease: 'Disminución',
    promo_code_not_supported_for_gift_cards:
        'El código promocional no es compatible con las tarjetas de regalo',
    select_products: 'Seleccionar productos',
    change_selected_products: 'Cambiar productos seleccionados',
    cartCompatibilityWarning:
        'Hay productos en tu carrito que no son compatibles con este producto. Por favor, cómpralos por separado o vacía tu carrito para continuar.',
    membershipBookingDisabledWarning:
        'La reserva de membresía está deshabilitada en este sitio web, pero este producto la requiere. Es muy probable que sea un error de configuración. Por favor, contacte con el servicio de atención al cliente.',
    see_available_tickets: 'Ver entradas disponibles',
} as any;

export default translations;
