import { tzdate } from '@repo/tzdate';
import { useState } from 'react';
import {
    CompletedPaymentInfo,
    GiftcardPaymentInfo,
    PaymentInfo,
    Purchase,
    CartItem,
    CheckoutInfo,
    AppliedGiftCard,
    BilberryPromoCodeStatus,
    Translations,
} from '@repo/types';
import { useAtom } from 'ximple';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import { showError } from 'src/utils/widget/error-handling';
import { capitalize } from '@repo/common-utils/TextUtils';
import { BilberryApiError } from '@repo/widget-utils/services/utils/BilberryApiError';
import { getBookingClosedItems } from '@repo/widget-utils/cart/cartUtils';
import { validateCheckoutInfoData } from '@repo/widget-utils/checkout-info-helper';
import {
    getCartItemDisplayStartDate,
    getCartItemDisplayTitle,
} from '@repo/widget-utils/display-helper';
import { guardIsValidPaymentGateway } from '@repo/widget-utils/reservation-helper';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import {
    voucherAtom,
    resetIgnoredValueCards,
    resetPromoCode,
    resetAppliedPromoCode,
} from 'src/state/voucher/voucherAtom';
import { paymentPlanAtom } from 'src/state/payment-plan/paymentPlanAtom';
import { CheckoutTabType, IdxOpts } from './views/getTabIndex';
import { cartAtom } from 'src/state/cart/cartAtom';
import { checkoutInfoAtom, reset } from 'src/state/checkout-info/checkoutInfoAtom';
import {
    bilberryReservationFromCart,
    getConsumerFromCheckoutInfo,
    getMembershipActivityReservationsFromCart,
    getTimeslotsFromCart,
} from '@repo/widget-utils/ProductMapper';
import { createReservation } from '@repo/widget-utils/services/fetchers/product';
import { companyAtom } from 'src/state/company';
import { postMultiReservation } from '@repo/widget-utils/services/fetchers/membership';
import { errorLog } from '@repo/common-utils/Logger';
import { clearCartEvent } from 'src/state/cart/cart.reducer';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { PriceSummary } from '@repo/widget-utils/price-helper';
import { findUrlQueryParam } from '@repo/common-utils/query-params-helper';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { storePurchase } from 'src/state/payment/purchase.localstorage';
import { useCartCheckoutContext } from './CartCheckoutContext';
import { useLocale } from '@repo/i18n';

export function usePayment() {
    const { t } = useLocale();
    const { mutateBookingsForUser, mutateValueCardsForUser } = useMemberContext();
    const [cartItems] = useAtom(cartAtom);
    const [{ appliedPromoCode, ignoredValueCards }, voucherDispatch] = useAtomReducer(voucherAtom);
    const [{ isUsingPaymentPlan }] = useAtom(paymentPlanAtom);
    const [checkoutInfoData, dispatchCheckoutInfo] = useAtomReducer(checkoutInfoAtom);
    const {
        priceSummary,
        appliedGiftcards,
        setActiveTab,
        setPaymentInfo,
        setCompletedPaymentInfo,
        setIsMakingReservation,
        idxOpts: tabIndexOpts,
        showAddressFields,
    } = useCartCheckoutContext();

    const [clickedNext, setClickedNext] = useState(false);

    async function handleGoPayClicked(paymentMethod?: 'invoice') {
        setClickedNext(true);

        if (!checkoutInfoData) return;

        const isCheckoutInfoDataValid = validateCheckoutInfoData(
            checkoutInfoData,
            showAddressFields,
        );

        if (!isCheckoutInfoDataValid) {
            showError(capitalize(t.please_fill_in_all_required_information));
            return;
        }

        const bookingClosedItems = getBookingClosedItems(cartItems);
        if (bookingClosedItems.length > 0) {
            const productNames = bookingClosedItems.map((item) =>
                capitalize(
                    t.booking_is_closed.parsed(
                        `"${getCartItemDisplayTitle(item)} - ${tzdate(
                            getCartItemDisplayStartDate(item),
                        ).format('lll')}"`,
                    ),
                ),
            );
            showError(productNames.join(', '));
            return;
        }

        reserve(
            t,
            cartItems,
            priceSummary,
            checkoutInfoData,
            setActiveTab,
            setPaymentInfo,
            setIsMakingReservation,
            appliedGiftcards,
            appliedPromoCode,
            tabIndexOpts,
            onPaymentCompleted,
            ignoredValueCards.valueCardIdsToIgnore,
            ignoredValueCards.valueCardProductIdsToIgnore,
            ignoredValueCards.valueCardProductTypeIdsToIgnore,
            isUsingPaymentPlan,
            paymentMethod,
        );
    }

    function onPaymentCancelled() {
        // TODO: what if the user go back, make some minor changes, and then want to proceed...
        // now we will make a new reservation.

        dispatchWidgetEvent({
            eventType: 'checkoutStep',
            cartItems: cartItems,
            giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                giftcardReference: appliedGiftcard.giftcardStatus.id,
                priceReduction: appliedGiftcard.priceReduction,
            })),
            promoCode: appliedPromoCode,
            checkoutStep: 'Cancel',
        });

        setPaymentInfo(null);
        setActiveTab('contactinfo');
    }

    function onPaymentCompleted(tabIndexOpts: Record<string, any>) {
        return async function (
            paymentInfo: PaymentInfo | GiftcardPaymentInfo,
            paymentGatewayResponse: any,
        ) {
            const knownPaymentInfoType = paymentInfo as PaymentInfo;
            const purchasedCartItems = cartItems;
            const completedPaymentInfo: CompletedPaymentInfo = {
                paymentInfo: knownPaymentInfoType,
                paymentGatewayResponse,
                purchasedCartItems,
                paymentId: knownPaymentInfoType.payment_id,
                referenceId: knownPaymentInfoType.reference,
            };

            dispatchWidgetEvent({
                eventType: 'checkoutStep',
                cartItems: purchasedCartItems,
                promoCode: appliedPromoCode,
                giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                    giftcardReference: appliedGiftcard.giftcardStatus.id,
                    priceReduction: appliedGiftcard.priceReduction,
                })),
                checkoutStep: 'Success',
            });

            dispatchWidgetEvent({
                eventType: 'book',
                cartItems: purchasedCartItems,
                promoCode: appliedPromoCode,
                giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                    giftcardReference: appliedGiftcard.giftcardStatus.id,
                    priceReduction: appliedGiftcard.priceReduction,
                })),
                reference:
                    knownPaymentInfoType.booking?.id.toString() ?? knownPaymentInfoType.reference,
            });

            await cartAtom.update(clearCartEvent());
            dispatchCheckoutInfo(reset());
            setPaymentInfo(null);
            setCompletedPaymentInfo(completedPaymentInfo);
            setActiveTab('payment');
            storePurchase(null);
            voucherDispatch(resetIgnoredValueCards());
            voucherDispatch(resetPromoCode());
            voucherDispatch(resetAppliedPromoCode());
            mutateBookingsForUser();
            mutateValueCardsForUser();

            const bbRebookReturnUrl = findUrlQueryParam('bbRebookReturnUrl');

            if (bbRebookReturnUrl) {
                window.location.href =
                    bbRebookReturnUrl + '?newInvoiceId=' + completedPaymentInfo.referenceId;
            }
        };
    }

    return {
        handleGoPayClicked,
        onPaymentCancelled,
        onPaymentCompleted,
        clickedNext,
        setClickedNext,
    };
}

async function reserve(
    t: Translations,
    cartItems: CartItem[],
    priceSummary: PriceSummary,
    checkoutInfoData: CheckoutInfo,
    setActiveTab: (arg: CheckoutTabType) => void,
    setPaymentInfo: (paymentInfo: PaymentInfo | null) => void,
    setIsMakingReservation: (isMakingReservation: boolean) => void,
    appliedGiftcards: AppliedGiftCard[],
    appliedPromoCode: BilberryPromoCodeStatus | null,
    tabIndexOpts: IdxOpts,
    onPaymentCompleted: (
        tabIndexOptions: IdxOpts,
    ) => (paymentInfo: PaymentInfo | GiftcardPaymentInfo, paymentGatewayResponse: any) => void,
    valueCardIdsToIgnore: number[],
    valueCardProductIdsToIgnore: number[],
    valueCardProductTypeIdsToIgnore: number[],
    isUsingPaymentPlan: boolean,
    paymentMethod?: 'invoice',
) {
    setIsMakingReservation(true);

    const appliedGiftcardIds = appliedGiftcards.map((x) => x.giftcardStatus.id);

    try {
        let paymentInfo: PaymentInfo | null = null;
        const purchase: Purchase = {
            referenceId: '',
            paymentId: '',
            amount: 0,
            cartItems: cartItems,
            giftcards: [],
            isAnalyticsNotified: false,
        };

        if (isUsingPaymentPlan && paymentMethod !== 'invoice') {
            const timeslotsReservation = getTimeslotsFromCart(
                cartItems,
                valueCardIdsToIgnore,
                valueCardProductIdsToIgnore,
                valueCardProductTypeIdsToIgnore,
            );

            const tours = getMembershipActivityReservationsFromCart(
                cartItems,
                checkoutInfoData,
                valueCardIdsToIgnore,
                valueCardProductIdsToIgnore,
                valueCardProductTypeIdsToIgnore,
            );

            const consumer = getConsumerFromCheckoutInfo(checkoutInfoData);

            const booking = await postMultiReservation(
                companyAtom.subject.value.currentSite!.key,
                consumer,
                {
                    timeslotReservations: timeslotsReservation?.reservations ?? [],
                    activityReservations: tours,
                    giftcardReferences: appliedGiftcardIds,
                    coupon_code: appliedPromoCode?.coupon_code,
                    checkoutUrl: window.location.origin + window.location.pathname,
                },
            );
            paymentInfo = {
                payment_gateway: 'nets',
                payment_id: booking?.paymentId ?? null,
                reference: booking?.orderReference ?? null,
                checkout_key: companyAtom.subject.value.currentSite!.netsCheckoutKey,
            };
            purchase.referenceId = booking?.orderReference ?? '';
            purchase.paymentId = booking?.paymentId ?? '';

            purchase.amount = priceSummary.totalPrice;
        } else {
            const bbRebookFromInvoiceIds = findUrlQueryParam('bbRebookFromInvoiceIds');

            const rebookFromInvoiceIds = bbRebookFromInvoiceIds
                ? bbRebookFromInvoiceIds.split(',').map((id) => parseInt(id))
                : [];

            const reservation = bilberryReservationFromCart(
                cartItems,
                checkoutInfoData,
                rebookFromInvoiceIds,
                appliedGiftcardIds,
                appliedPromoCode?.coupon_code,
            );
            const booking = await createReservation(
                reservation,
                paymentMethod,
                checkoutInfoData.contactType,
            );
            guardIsValidPaymentGateway(booking);
            paymentInfo = {
                payment_gateway: booking?.payment_gateway as PaymentInfo['payment_gateway'],
                payment_id: booking.payment_id,
                reference: booking.id.toString(),
                checkout_key: booking.checkout_key ?? undefined,
                reservation,
                booking,
            };
            purchase.referenceId = booking?.id.toString() ?? '';
            purchase.paymentId = booking?.payment_id ?? '';
            purchase.amount = booking.total;
        }

        storePurchase(purchase);

        // Bilberry backoffice specific behavior
        if (configurationAtom.subject.value.backoffice_skipPayment) {
            onPaymentCompleted(tabIndexOpts)(paymentInfo, null);
            return;
        }

        if (
            (paymentMethod === 'invoice' && paymentInfo.payment_gateway === 'invoice') ||
            (!paymentInfo.payment_id &&
                paymentInfo.reference &&
                !paymentInfo.booking?.stripe_key) ||
            (paymentInfo.booking?.paid_at && !paymentInfo.payment_id && !paymentInfo.checkout_key)
        ) {
            // Due to applied giftcard or invoice payment
            // Go straight to receipt
            //
            onPaymentCompleted(tabIndexOpts)(paymentInfo, null);
        } else {
            // Proceed to the NETS payment
            setPaymentInfo(paymentInfo);
            setActiveTab('payment');
            dispatchWidgetEvent({
                eventType: 'paymentInfoAdded',
                cartItems: cartItems,
                giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                    giftcardReference: appliedGiftcard.giftcardStatus.id,
                    priceReduction: appliedGiftcard.priceReduction,
                })),
                promoCode: appliedPromoCode,
            });
        }
    } catch (error) {
        errorLog('error: ', error);

        if (error instanceof BilberryApiError) {
            const errorDetails = await error.errorDetails();
            const errorMessages = await error.errorMessages();

            if (error.response.status === 422) {
                showError(errorMessages.join('\n'));
                return;
            }

            if (error.response.status === 406) {
                showError(errorDetails.detail || errorMessages[0]);
                return;
            }

            if (errorMessages.length > 0) {
                showError(errorMessages.join('\n'));
                return;
            }
        }

        showError(capitalize(t.error_occurred_when_creating_reservation));
    } finally {
        setIsMakingReservation(false);
    }
}
