import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useAtom } from 'ximple';
import { useAtomReducer } from 'src/hooks/useAtomReducer';
import useCartItemsWithMembershipAddedOrRemoved from 'src/hooks/domain/cart/useCartItemsWithMembershipAddedOrRemoved';
import { cartAtom } from './cartAtom';
import { addOrReplaceCartEvent } from './cart.reducer';
import { paymentPlanAtom } from '../payment-plan/paymentPlanAtom';

export function useCartMembershipEffects() {
    const [cart, cartDispatch] = useAtomReducer(cartAtom);
    const [{ isUsingPaymentPlan, paymentPlanResponse }] = useAtom(paymentPlanAtom);

    const cartItemsMembership = useCartItemsWithMembershipAddedOrRemoved(
        cart,
        isUsingPaymentPlan,
        paymentPlanResponse ?? null,
    );

    useEffect(() => {
        const plans = isUsingPaymentPlan && paymentPlanResponse;

        if (!plans || isEqual(cart, cartItemsMembership)) return;

        cartDispatch(addOrReplaceCartEvent(cartItemsMembership));
    }, [cart, cartDispatch, isUsingPaymentPlan, paymentPlanResponse, cartItemsMembership]);
}
