import produce from 'immer';
import { atom } from 'ximple';
import { BilberryGiftcardStatus, BilberryPromoCodeStatus } from '@repo/types';
import { VERSION } from '@repo/widget-env/__autogen/env';
import { LocalStorageKey } from '../localstorage';

export type IgnoredValueCards = {
    valueCardIdsToIgnore: number[];
    valueCardProductIdsToIgnore: number[];
    valueCardProductTypeIdsToIgnore: number[];
};

type State = {
    appliedPromoCode: BilberryPromoCodeStatus | null;
    giftcards: BilberryGiftcardStatus[];
    ignoredValueCards: IgnoredValueCards;
};

type SetAppliedPromoCodeAction = {
    type: 'setAppliedPromoCode';
    value: State['appliedPromoCode'];
};

type SetGiftcardsAction = {
    type: 'setGiftcards';
    value: State['giftcards'];
};

type SetIgnoredValueCardsAction = {
    type: 'setIgnoredValueCards';
    value: State['ignoredValueCards'];
};

type ResetIgnoredValueCardsAction = {
    type: 'resetIgnoredValueCards';
    value?: undefined;
};

type Actions =
    | SetAppliedPromoCodeAction
    | SetGiftcardsAction
    | SetIgnoredValueCardsAction
    | ResetIgnoredValueCardsAction;

export const voucherAtom = atom<State, Actions>({
    initialValue: {
        appliedPromoCode: null,
        giftcards: [],
        ignoredValueCards: {
            valueCardIdsToIgnore: [],
            valueCardProductIdsToIgnore: [],
            valueCardProductTypeIdsToIgnore: [],
        },
    },
    persistKey: LocalStorageKey.BILBERRY_VOUCHER,
    appVersion: VERSION,
    update: produce((state: State, { type, value }: Actions): State => {
        switch (type) {
            case 'setAppliedPromoCode':
                state.appliedPromoCode = value;
                break;
            case 'setGiftcards':
                state.giftcards = value;
                break;
            case 'setIgnoredValueCards':
                state.ignoredValueCards = value;
                break;
            case 'resetIgnoredValueCards':
                state.ignoredValueCards = {
                    valueCardIdsToIgnore: [],
                    valueCardProductIdsToIgnore: [],
                    valueCardProductTypeIdsToIgnore: [],
                };
                break;
        }

        return state;
    }),
});

export function setAppliedPromoCode(
    value: SetAppliedPromoCodeAction['value'],
): SetAppliedPromoCodeAction {
    return { type: 'setAppliedPromoCode', value };
}

export function setGiftcards(value: SetGiftcardsAction['value']): SetGiftcardsAction {
    return { type: 'setGiftcards', value };
}

export function setIgnoredValueCards(
    value: SetIgnoredValueCardsAction['value'],
): SetIgnoredValueCardsAction {
    return { type: 'setIgnoredValueCards', value };
}

export function resetIgnoredValueCards(): ResetIgnoredValueCardsAction {
    return { type: 'resetIgnoredValueCards' };
}
